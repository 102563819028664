import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import loopStyles from 'common/styles'
import { CompanyType, CSSPosition, FeatureType } from 'common/constants';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Feature, FeatureData } from 'common/feature';
import styled from 'styled-components';

interface RouteSidebarProps {
    isOpen: boolean,
    classes: any,
    deselectAll: () => void,
    selectedFeatureID: string | undefined,
    regionalViews: any,
    selectFeature: (featureID: string | undefined, zoom?: boolean) => void,
    highlightedFeatureIDs: string[],
    toggleHighlightedFeatureID: (featureID: string) => void,
    filterCompany: any,
    featureData: FeatureData,
    setShowLoopTooltip?: any,
    setShowHyperloopTooltip?: any,
    firestoreDB: any,
}

interface RouteSidebarState {
    searchString: string,
    isSignedIn: boolean,
    username: string | null,
    searchResults: any[],
    featureDictionary: Record<string, any>,

    hyperloopContent: React.ReactElement[],
    loopContent: React.ReactElement[],
    regionalViewsContent: React.ReactElement[],
}

class RouteSidebar extends React.Component<RouteSidebarProps, RouteSidebarState> {
    state = {
        featureDictionary: {},
        searchString: "",
        searchResults: [],
        username: '',
        isSignedIn: false,
        hyperloopContent: [],
        loopContent: [],
        regionalViewsContent: [],
    }

    HyperloopTooltipMessage = 'Privately funded companies pursuing hyperloop technology (train-like travel at ~700mph) and proposing inter-city routes around the world.';
    TeamsTooltipMessage = 'Academic or Competition teams designing and building hyperloop pods to advance the technology & reach faster speeds.';
    LoopTooltipMessage = 'Loop transportation technology is for intra-city travel at speeds up to 120mph and underground.';

    componentDidMount() {
        this.setState({ isSignedIn: false })
        this.initContent();
    }

    render() {
        const { isOpen, classes, setShowHyperloopTooltip, setShowLoopTooltip } = this.props;

        return (
            <Drawer
                className={isOpen ? classes.drawer : classes.closedDrawer}
                variant="persistent"
                anchor="left"
                open={isOpen}
                classes={{ paper: classes.drawerPaper }}
            >
                {/* <div style={{ backgroundColor: '#1C1B1D', border: '1px solid #313132', borderRadius: '9px', opacity: '70%', margin: '10px 10%', marginBottom: 0, height: '50px'}}>
                    <InputBase className={classes.searchBar} autoFocus={true} placeholder="Search..." onChange={(e) => {
                        this.getSearchResults(e.target.value)
                    }} />
                </div> */}
                <List
                    className={classes.list}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {/* {searchResults.length > 0 && searchResults} */}
                        <>
                            <ContentHeader>
                                Hyperloop Companies
                                <img 
                                    src={`${process.env.PUBLIC_URL}/icons/Tooltip.png`} 
                                    alt={'img'} 
                                    style={{marginRight: '32px'}} 
                                    onMouseOver={() => setShowHyperloopTooltip(true)} 
                                    onMouseLeave={() => setShowHyperloopTooltip(false)} 
                                />
                            </ContentHeader>
                            <ContentContainer>
                                {/* {hyperloopContent} */}
                                {/* {Object.values(featureData).filter(feature => feature.featureType === FeatureType.Company && feature.companyType === CompanyType.Hyperloop).map(company => {
                                    return (
                                        <FeatureGroup
                                            label={company['Hyperloop Teams']}
                                            value={company.featureID}
                                            icon={`${company.companyID}.png`}
                                            selectedFeatureID={selectedFeatureID}
                                            key={`featureGroup:${company.companyID}`}
                                            // secondaryAction={filterCompany}
                                            selectFeature={selectFeature}
                                            features={Object.values(featureData).filter(feature => feature.RouteName && feature.companyID === company.companyID)}
                                        >
                                            
                                        </FeatureGroup>
                                    )
                                })} */}
                            </ContentContainer>
                            <ContentHeader spaceAbove={true}>
                                Loop Companies
                                <img 
                                    src={`${process.env.PUBLIC_URL}/icons/Tooltip.png`} 
                                    alt={'img'} 
                                    style={{marginRight: '32px'}}
                                    onMouseOver={() => setShowLoopTooltip(true)} 
                                    onMouseLeave={() => setShowLoopTooltip(false)}
                                />
                            </ContentHeader>
                            <ContentContainer>
                                {/* {loopContent} */}
                                {/* {Object.values(featureData).filter(feature => feature.featureType === FeatureType.Company && feature.companyType === CompanyType.Loop).map(company => {
                                    return (
                                        <FeatureGroup
                                            label={company['Hyperloop Teams']}
                                            value={company.featureID}
                                            icon={`${company.companyID}.png`}
                                            selectedFeatureID={selectedFeatureID}
                                            key={`featureGroup:${company.companyID}`}
                                            selectFeature={selectFeature}
                                            secondaryAction={filterCompany}
                                            features={Object.values(featureData).filter(feature => feature.RouteName && feature.companyID === company.companyID)}
                                        >
                                        </FeatureGroup>
                                    )
                                })} */}
                            </ContentContainer>
                            {/* <ContentHeader spaceAbove={true}>
                                Regional Views
                            </ContentHeader>
                            <ContentContainer>
                                {regionalViewsContent}
                            </ContentContainer> */}
                        </>
                </List>
            </Drawer>);
    }

    responseGoogle = (response: any) => {
        this.setState({ isSignedIn: true, username: response.profileObj.givenName })
    }

    onLogout = () => {
        this.setState({ isSignedIn: false, username: null })
    }

    // getSearchResults(searchString: string) {
    //     const { goToFeature, classes, featureData } = this.props;

    //     const newSearchString = searchString.toLowerCase();
    //     let results: any[] = []
    //     Object.keys(featureData).forEach(key => {
    //         const route = featureData[key]
    //         if (route.featureType === 'route' && route.RouteName.toLowerCase().indexOf(newSearchString)){
    //             results.push(
    //                 <div key={`${route.RouteName}`}>
    //                     <ListItem key={`featureListItem:${route.featureID}`} button onClick={() => { goToFeature(route.featureID) }} className={classes.selected} >
    //                         <ListItemIcon className={classes.childIcon}>
    //                             <TimelineIcon />
    //                         </ListItemIcon>
    //                         <ListItemText primary={route.RouteName} />
    //                     </ListItem>
    //                 </div>
    //             )
    //         }
    //     })
    //     this.setState({searchString, searchResults: results}, () => console.log(this.state)) 
    // }

    mapViewFeatureToListItem = (view: Feature): React.ReactElement => {
        const { selectFeature, classes } = this.props;

        return (<ListItem key={`${view.featureID}`} button onClick={() => { selectFeature(view.featureID) }} className={classes.regionalViewItem} >
            <ListItemText 
                disableTypography
                primary={<Typography className={classes.regionalViewLabel}>{view.regionName}</Typography>}
            />
        </ListItem>)
    }

    mapRouteOrPointToListItem = (feature: Feature): React.ReactElement => {
        const { selectFeature, classes, selectedFeatureID } = this.props;
        // Update this with selectedFeatureID
        const selected = feature.featureID === selectedFeatureID;
        const companyStyles = loopStyles[feature.companyID];

        return (
            <ListItem key={`featureListItem:${feature.featureID}`} className={classes.selected} onClick={() => { selectFeature(feature.featureID, true) }} >
                {/* {feature.featureType === FeatureType.Route ? 
                     : 
                    (
                        <div style={{ minWidth: '56px', height: '24px', display: 'flex', alignItems: 'center' }}>
                            <div style={{height: '24px', width: '24px', display: 'flex', justifyContent: 'center'}}>
                                <img src={`${process.env.PUBLIC_URL}/icons/companyIcons/HQ.png`} alt={'img'} style={{width: '18px', height: '18px'}} />
                            </div>
                        </div>
                    )
                } */}
                <ListItemText 
                    disableTypography
                    className={classes.listItemText}
                    primary={<Typography className={classes.label}>{feature.RouteName}</Typography>}
                />
                <IconButton className={classes.childIcon} style={{color: companyStyles ? companyStyles.colors.primary : loopStyles.colors.tertiary}} onClick={() => {this.handleFilterButton(feature.featureID)}}>
                    {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                </IconButton> 
            </ListItem>
        )
    }

    handleFilterButton = (clickedFeatureID: string) => {
        const { selectedFeatureID, selectFeature, toggleHighlightedFeatureID } = this.props;

        if (selectedFeatureID === clickedFeatureID) {
            selectFeature(undefined)
        } else {
            toggleHighlightedFeatureID(clickedFeatureID)
        }
    }

    // mapCompanyFeatureListToContent = (companies: Feature[], featureList: Feature[]): React.ReactElement[] => {
    //     const { selectedFeatureID } = this.props;

    //     return companies.map(company => {
    //         return (
    //             <>
    //                 <FeatureGroup
    //                     label={company['Hyperloop Teams']}
    //                     value={company.featureID}
    //                     icon={`${company.companyID}.png`}
    //                     selectedFeatureID={selectedFeatureID}
    //                 >
    //                     {featureList.filter(feature => feature.companyID === company.companyID).map(this.mapRouteOrPointToListItem)}
    //                 </FeatureGroup>
    //             </>
    //         )
    //     })
    // }

    initContent = (): void => {
        const { featureData } = this.props;

        const featureList = Object.values(featureData)

        // const regionalViews: Feature[] = featureList.filter(feature => feature.featureType === FeatureType.View)
        // const regionalViewsContent = regionalViews.map(this.mapViewFeatureToListItem)
        
        const companies: Feature[] = featureList.filter(feature => feature.featureType === FeatureType.Company)
        let companyTypeDictionary: {[featureID: string]: CompanyType}= {};
        companies.forEach((company: Feature) => {
            companyTypeDictionary[company.companyID] = company.companyType
        })

        // const hyperloopFeatures: Feature[] = featureList.filter(feature => feature.featureType !== FeatureType.Company && companyTypeDictionary[feature.companyID] === CompanyType.Hyperloop)
        // const hyperloopContent = this.mapCompanyFeatureListToContent(companies.filter(feature => feature.companyType === CompanyType.Hyperloop), hyperloopFeatures)

        // const loopFeatures: Feature[] = featureList.filter(feature => feature.featureType !== FeatureType.Company && companyTypeDictionary[feature.companyID] === CompanyType.Loop)
        // const loopContent = this.mapCompanyFeatureListToContent(companies.filter(feature => feature.companyType === CompanyType.Loop), loopFeatures)

        // this.setState({ regionalViewsContent })
    }

    buildFeatureDictionary() {
        const { featureData } = this.props;
        let featureDictionary: any = {};

        Object.keys(featureData).forEach(featureKey => {
            const feature = featureData[featureKey];

            if (feature.featureType === 'route' || feature.featureType === 'point') {
                if (!featureDictionary[feature.companyID]) {
                    featureDictionary[feature.companyID] = []
                }
                featureDictionary[feature.companyID].push({
                    value: feature.featureID,
                    label: feature.RouteName,
                    type: feature.featureType,
                })
            } else if (feature.featureType === 'team') {
                if (!featureDictionary.teams) {
                    featureDictionary.teams = []
                }
                featureDictionary.teams.push({
                    value: feature.featureID,
                    label: feature["Hyperloop Teams"],
                    type: feature.featureType,
                })
            }
        })
        this.setState({ featureDictionary });
    }

    // getRouteList(key: string) {
    //     const { selectFeature, classes, selectedFeatureID } = this.props;
    //     const { featureDictionary } = this.state;
    //     const colors = loopStyles[key].colors;

    //     return ((featureDictionary as any)[key] &&
    //         <List component="div" disablePadding>
    //             {(featureDictionary as any)[key].map((item: any) => {
    //                 const selected = item.value === selectedFeatureID;
    //                 return (
    //                     <div key={`${item.label}`}>
    //                         <ListItem key={`featureListItem:${item.label}`} button onClick={() => { selectFeature(item.value) }} className={classes.selected} >
    //                             {item.type === 'route' ? <ListItemIcon className={classes.childIcon} style={{color: colors.primary}}>
    //                                 {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
    //                             </ListItemIcon> : <div style={{ minWidth: '56px', height: '24px', display: 'flex', alignItems: 'center' }}>
    //                                 <div style={{height: '24px', width: '24px', display: 'flex', justifyContent: 'center'}}>
    //                                     <img src={`${process.env.PUBLIC_URL}/icons/companyIcons/HQ.png`} alt={'img'} style={{width: '18px', height: '18px'}} />
    //                                 </div>
    //                             </div>}
    //                             <ListItemText 
    //                                 disableTypography
    //                                 primary={<Typography className={classes.label}>{item.label}</Typography>}
    //                             />
    //                         </ListItem>
    //                     </div>
    //                 );
    //             })}
    //         </List>
    //     );
    // }
}

const ContentHeader = styled.div<{spaceAbove?: boolean}>(
    {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '24px',
        color: '#696974',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    }, 
    ({ spaceAbove }) => ({
        marginTop: spaceAbove ? '24px' : '0',
    })
)

const ContentContainer = styled.div({
    // marginTop: '10px'
})

export const drawerWidth = 300;
const styles = {
    root: {
        display: 'flex',
    },
    barColorPrimary: {
        backgroundColor: loopStyles.colors.primary,
    },
    childIcon: {
        color: loopStyles.colors.secondary,
        height: '100%',
        width: '60px',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: `${loopStyles.colors.secondary}`
        }
    },
    regionalViewItem: {
        paddingLeft: '24px',
        height: '40px',
    },
    regionalViewLabel: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        color: '#B5B5BE',
    },
    clearAllIcon: {
        position: CSSPosition.Absolute,
    },
    clearAllButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: loopStyles.colors.tertiary,
        width: '40px',
        height: '40px',
    },
    drawer: {
        width: drawerWidth,
        height: '100%',
        overflow: 'hidden',
        position: CSSPosition.Absolute,
        zIndex: 4,
        borderRight: '2px solid #333233',
    },
    closedDrawer: {
        width: 0,
        overflow: 'hidden',
    },
    drawerPaper: {
        width: '100%',
        maxWidth: drawerWidth,
        height: '100%',
        position: CSSPosition.Absolute,
        // zIndex: 4,
        background: 'rgba(35, 34, 36, 0.9)',
        overflow: 'hidden',
        'backdrop-filter': 'blur(6px)',
    },
    list: {
        width: '100%',
        height: '100%',
        maxWidth: drawerWidth,
        display: 'inline-block',
        overflowX: 'hidden' as any,
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: loopStyles.colors.tertiary,
            border: '4px solid transparent',
            borderRadius: '16px',
        },
        overflowY: 'scroll' as any,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '0.1px',
        paddingLeft: '20px',
        height: '100%',
        cursor: 'pointer',
    },
    modal: {
        opacity: 0.3
    },
    searchBar: {
        color: 'rgba(146, 146, 157, 1.0)',
        opacity: 100,
        height: '100%',
        margin: '0 10px',
        width: '100%'
    },
    selected: {
        height: '50px',
        padding: 0,
        color: '#FFFFFF',
        opacity: 1,
        '&:hover': {
            color: `${loopStyles.colors.primary}`,
            backgroundColor: `${loopStyles.colors.secondary}`
        }
    },
    listItemText: {
        height: '100%',
    },
    title: {
        color: loopStyles.colors.tertiary,
        fontFamily: 'OneDay'
    },
    tooltip: {
        marginTop: '40px'
    }
};

export default withStyles(styles)(RouteSidebar);