import loopStyles from "common/styles"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { createAccount, login, resetPassword, resendVerfication, logout } from "store/reducers/user";
import CircularProgress from "@material-ui/core/CircularProgress";

enum LoginContent {
    CreateAccount,
    ResetPassword
}

type LoginProps = {
    analytics: any,
}

const Login = (props: LoginProps) => {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user.user)
    const isLoading = useSelector((state: any) => state.user.isLoading)

    // Component state
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [loginContent, setLoginContent] = useState<LoginContent | undefined>(undefined)
    const [isCreateSent, setIsCreateSent] = useState(false)
    const [isResetSent, setIsResetSent] = useState(false)

    const handleLoginClick = () => {
        dispatch(login({ email, password }))
    }
    
    const handleLogoutClick = () => {
        dispatch(logout())
    }
    
    const handleResendVerificationClick = () => {
        dispatch(resendVerfication(user))
    }

    const handleCreateAccountClick = () => {
        dispatch(createAccount({ email, password }))
        setIsResetSent(true)
    }
    
    const handleResetPasswordClick = () => {
        dispatch(resetPassword({ email }))
        setIsResetSent(true)
    }

    useEffect(() => {
        setEmail('')
        setPassword('')
        setIsCreateSent(false)
        setIsResetSent(false)
    }, [ loginContent ])

    useEffect(() => {
        props.analytics.logEvent(`Login loaded`);
    }, []) // eslint-disable-line

    const getContent = () => {
        if (user && !user.emailVerified) {
            return <>
                <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>You're logged in, but you haven't verified your account.</div>
                <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Please check your email for verifcation, and refresh this page when you've completed it.</div>
                <Button onClick={handleResendVerificationClick}>Re-send verification email</Button>
                <div style={{ display: 'flex', marginTop: '40px', textAlign: 'center', color: loopStyles.colors.secondary, fontFamily: 'Poppins'}}>Or</div>
                <Button onClick={handleLogoutClick}>Log Out</Button>
            </>
        }

        switch (loginContent) {
            case LoginContent.CreateAccount:
                return <>
                    {isCreateSent ? <>
                        <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>We've sent an email to: {email}.</div>
                        <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>You'll need to confirm your account before you can access Hypermap.</div>
                    </> : <>
                        <InputContainer>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Email</div>
                            <Input value={email} onChange={(ev: any) => { setEmail(ev.target.value) }} id='login-username' placeholder='Email'/>
                        </InputContainer>
                        <InputContainer>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Password</div>
                            <Input value={password} onChange={(ev: any) => { setPassword(ev.target.value) }} id='login-password' type='password' placeholder='Password'/>
                        </InputContainer>
                        <InputContainer>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Password (again)</div>
                            <Input value={password2} onChange={(ev: any) => { setPassword2(ev.target.value) }} id='login-password-two' type='password' placeholder='Password (again)'/>
                        </InputContainer>
                        <Button onClick={handleCreateAccountClick}>Create Account</Button>
                    </>}
                    <Link onClick={() => { setLoginContent(undefined) }}>Back</Link>
                </>
            case LoginContent.ResetPassword:
                return <>
                    {isResetSent ? <>
                        <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>If {email} is currently registered, you will receieve an email that will prompts a password reset.</div>
                    </> : <>
                        <InputContainer>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Email</div>
                            <Input value={email} onChange={(ev: any) => { setEmail(ev.target.value) }} id='login-username' placeholder='Email'/>
                        </InputContainer>
                        <Button onClick={handleResetPasswordClick}>Reset Password</Button>
                    </>}
                    <Link onClick={() => { setLoginContent(undefined) }}>Back</Link>
                </>
            default:
                return <>
                    <InputContainer>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Email</div>
                        <Input value={email} onChange={(ev: any) => { setEmail(ev.target.value) }} id='login-username' placeholder='Email'/>
                    </InputContainer>
                    <InputContainer>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Password</div>
                        <Input value={password} onChange={(ev: any) => { setPassword(ev.target.value) }} id='login-password' type='password' placeholder='Password'/>
                    </InputContainer>
                    {isLoading ? <CircularProgress style={{ color: loopStyles.colors.tertiary }} /> : <Button onClick={handleLoginClick}>Log In</Button>}
                    <Link onClick={() => { setLoginContent(LoginContent.ResetPassword) }}>Reset Password</Link>
                    <Link onClick={() => { setLoginContent(LoginContent.CreateAccount) }}>Create Account</Link>
                </>
        }
    }

    return <Container>
        <div style={{ width: 'calc(100% - 64px)', padding: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '40px'}}>
                <Logo />
                <div style={{ color: loopStyles.colors.tertiary, fontFamily: 'OneDay', fontSize: '36px', lineHeight: '36px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    HyperMap
                </div>
            </div>
            {getContent()}
        </div>
    </Container>
}

const InputContainer = styled.div`
    ${loopStyles.mediaQueries.mobile} {
        width: 100%;
    }
`

const Input = styled.input`
    color: ${loopStyles.colors.secondary};
    background-color: #343436 !important;
    border-radius: 6px;
    border: 1px solid rgba(255,255,255,0.1);
    padding: 10px;
    font-family: Poppins;
    font-size: 16px;
    margin-bottom: 20px;
    outline: none;
    ${loopStyles.mediaQueries.desktop} {
        width: 240px;
    }
    ${loopStyles.mediaQueries.mobile} {
        width: calc(100% - 20px);
    }
    -moz-transition: border .2s ease-in;
    -o-transition: border .2s ease-in;
    -webkit-transition: border .2s ease-in;
    transition: border .2s ease-in;
    &:hover {
        border: 1px solid rgba(255,255,255,0.6);
    }
    &:focus {
        border: 1px solid ${loopStyles.colors.tertiary} !important;
        border-color: ${loopStyles.colors.tertiary} !important;
    }
`

const Button = styled.button`
    cursor: pointer;
    background-color: ${loopStyles.colors.tertiary};
    color: ${loopStyles.colors.primary};
    border: 0;
    ${loopStyles.mediaQueries.desktop} {   
        margin-top: 40px;
        min-width: 320px;
        font-size: 1rem;
    }
    ${loopStyles.mediaQueries.mobile} {   
        margin-top: 20px;
        width: calc(100% - 20px);
        max-width: 100%;
        font-size: 1rem;
    }
    height: 48px;
    border-radius: 500px;
    font-size: 20px;
    white-space: nowrap;
    font-family: Poppins;
    letter-spacing: 0.5px;
`

const Link = styled.div`
    color: ${loopStyles.colors.tertiary};
    text-decoration: underline;
    font-family: Poppins;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    height: 40px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    ${loopStyles.mediaQueries.desktop} {
        width: 200px;
        &:hover {
            border: 1px solid ${loopStyles.colors.tertiary};
        }
    }
    ${loopStyles.mediaQueries.mobile} {
        width: 100%;
        max-width: 100%;
    }
    ${loopStyles.transition.animationStyles}
    // border: 1px solid ${loopStyles.colors.tertiary};
`

const Logo = styled.div`
    background-image: url(${process.env.PUBLIC_URL}/hyperloopFav.png);
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
    background-size: cover;
`

const Container = styled.div`
    position: absolute;
    width: calc(100vw - 2px);
    height: calc(100vh - 2px);
    max-height: calc(100dvh - 2px);
    top: 0;
    left: 0;
    background-color: ${loopStyles.colors.primary};
    display: flex;
    // border: 1px solid green;
    flex-direction: column;
    align-items: center;
    ${loopStyles.mediaQueries.desktop} {
        justify-content: center;
        height: 100%;
    }
    ${loopStyles.mediaQueries.mobile} {
        // height: 100lvh;
        justify-content: center;
        color: white;
    }
`

export default Login