import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { motion } from "framer-motion"
import { getAllFeatures } from "./common/functions"
import loopStyles from 'common/styles';
// import MobileMap from 'components/Map/MobileMap';
import { MapboxFeature, MapboxFeatureDictionary } from 'common/feature';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
// import * as firebaseui from 'firebaseui';
import firebaseConfig from 'firebaseConfig'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Map from 'components/Map/Map';
import { useDispatch, useSelector } from 'react-redux';
import { logout, restoreUserSession } from 'store/reducers/user';
import MapView from 'components/pages/MapView/MapView';
import { updateIsMobile } from 'store/reducers/global';
import ConstructionIcon from '@mui/icons-material/Construction';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from 'components/pages/Login/Login';

// let ui: any = undefined
let analytics: any = undefined
let firestoreDB : any= undefined
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  analytics = firebase.analytics();
  firebase.analytics().logEvent('app_loaded')
  firestoreDB = firebase.firestore();
}

declare global {
    interface Window { gapi: any; }
}

const App = () => {
  const auth = getAuth()
  const dispatch = useDispatch()

  const user = useSelector((state: any) => state.user.user)
  const isUserLoading = useSelector((state: any) => state.user.isLoading)

  // const [isMobileDevice, setIsMobileDevice] = useState(isMobile())
  const [mapboxFeatureDictionary, setMapboxFeatureDictionary] = useState<MapboxFeatureDictionary | undefined>(undefined)

  onAuthStateChanged(auth, (user) => {
    dispatch(restoreUserSession(user))
  })
  
  const buildmapboxFeatureDictionary = async () => {
    const [featureList] = await Promise.all([getAllFeatures()]);
    let mapboxFeatureDictionary: MapboxFeatureDictionary = {}
    featureList.forEach((feature: MapboxFeature) => {
      if (!mapboxFeatureDictionary[feature.properties!.featureID]) {
        mapboxFeatureDictionary[feature.properties!.featureID] = feature;
      }
    });
    setMapboxFeatureDictionary(mapboxFeatureDictionary);
  }

  // const startFirebaseLogin = () => {
  //   const uiconfig = {
  //     // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  //     signInFlow: 'popup',
  //     signInSuccessUrl: '',
  //     signInOptions: [
  //       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  //       firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //     ],
  //   };
  //   ui.start('#firebaseui-auth-container', uiconfig);
  // }

  useEffect(() => {
    // startFirebaseLogin();

    window.matchMedia(`(max-width: 768px)`).addEventListener('change', (ev: MediaQueryListEvent) => {
      dispatch(updateIsMobile(ev.matches))
    })
  }, [dispatch])

  useEffect(() => {
    if (isUserLoading === false) {
      if (!user?.emailVerified) {
        // user is not logged in
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
      } else {
        // user is logged in
        firebase.analytics().logEvent(`User logged in`);
        buildmapboxFeatureDictionary()
        if (window.location.pathname !== '/' && window.location.pathname !== '/spin' && window.location.pathname !== '/old') {
          window.location.href = '/'
        }
      }
    }
  }, [user]) // eslint-disable-line

  const loaded = mapboxFeatureDictionary !== undefined;

  return <ErrorBoundary>
      <Container id='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/login'  element={<Login analytics={analytics} />} />
            {/* Remove BETA USER check when pathfinder goes live */}
            <Route path='/' element={loaded && user?.emailVerified ? 
              (process.env.REACT_APP_BETA_USERS?.includes(user?.email) ? <MapView
                  mapboxFeatures={mapboxFeatureDictionary!}
                  analytics={analytics}
                  firestoreDB={firestoreDB}
              /> : <UnderConstruction>
                <ConstructionIcon style={{ width: '240px', height: '240px', fill: loopStyles.colors.tertiary }}/>
                <div style={{fontSize: '42px'}}>Under Construction</div>
                <div style={{fontSize: '24px', fontFamily: 'Poppins', opacity: 0.4, margin: '10px 0'}}>Big things are coming. Come back soon!</div>
                <Button onClick={() => { dispatch(logout()) }}>Log Out</Button>
              </UnderConstruction>) : <SpinnerContainer id='Spinner' animate={{ scale: [1.0, 1.2, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}>
                <div style={{position: 'relative'}}>
                  <Spinner src={`${process.env.PUBLIC_URL}/hyperloopFav.png`} alt={'HyperMap Logo'} />
                  <SpinnerShadow animate={{ scale: [1.0, 2.4, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}/>
                </div>
              </SpinnerContainer>}
            />
            {/* <Route path='/' element={process.env.REACT_APP_BETA_USERS?.includes(user?.email) ? (user?.emailVerified && loaded ? <MapView
                  mapboxFeatures={mapboxFeatureDictionary!}
                  analytics={analytics}
                  firestoreDB={firestoreDB}
              /> : <SpinnerContainer id='Spinner' animate={{ scale: [1.0, 1.2, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}>
                <div style={{position: 'relative'}}>
                  <Spinner src={`${process.env.PUBLIC_URL}/hyperloopFav.png`} alt={'HyperMap Logo'} />
                  <SpinnerShadow animate={{ scale: [1.0, 2.4, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}/>
                </div>
              </SpinnerContainer>) : <UnderConstruction>
                <ConstructionIcon style={{ width: '240px', height: '240px', fill: loopStyles.colors.tertiary }}/>
                <div style={{fontSize: '42px'}}>Under Construction</div>
                <div style={{fontSize: '24px', fontFamily: 'Poppins', opacity: 0.4, margin: '10px 0'}}>Big things are coming. Come back soon!</div>
                <Button onClick={() => { dispatch(logout()) }}>Log Out</Button>
              </UnderConstruction>}
            /> */}
            <Route path='/old' element={process.env.REACT_APP_BETA_USERS?.includes(user?.email) && loaded ? 
              <Map
                mapboxFeatures={mapboxFeatureDictionary!}
                analytics={analytics}
                firestoreDB={firestoreDB}
              /> : <SpinnerContainer id='Spinner' animate={{ scale: [1.0, 1.2, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}>
                <div style={{position: 'relative'}}>
                  <Spinner src={`${process.env.PUBLIC_URL}/hyperloopFav.png`} alt={'HyperMap Logo'} />
                  <SpinnerShadow animate={{ scale: [1.0, 2.4, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}/>
                </div>
              </SpinnerContainer>} 
            />
          </Routes>
        </BrowserRouter>
      </Container>
    </ErrorBoundary>
}

const UnderConstruction = styled.div`
  color: ${loopStyles.colors.secondary};
  font-family: Roboto;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: ${loopStyles.colors.primary};
`

const Spinner = styled.img`
  width: 250px;
  height: 250px;
  position: relative;
`

const SpinnerShadow = styled(motion.div)`
  position: absolute;
  width: 100px;
  height: 30px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3), transparent);
  bottom: -80px;
  left: calc(50% - 50px);
  transform: translateX(-50%);
  border-radius: 50%; /* Make it elliptical */
  z-index: -1; /* Place the shadow behind the sphere */
`

const SpinnerContainer = styled(motion.div)`
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: ${loopStyles.colors.primary};
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: -webkit-fill-available;
  min-height: -webkit-fill-available;
  overflow: hidden;
  background-color: ${loopStyles.colors.primary};
  ${loopStyles.mediaQueries.mobile} {
    max-height: 100dvh;
  }
`

const Button = styled.button`
    cursor: pointer;
    background-color: ${loopStyles.colors.tertiary};
    color: ${loopStyles.colors.primary};
    border: 0;
    ${loopStyles.mediaQueries.desktop} {   
        margin-top: 40px;
        min-width: 320px;
        font-size: 1rem;
    }
    ${loopStyles.mediaQueries.mobile} {   
        margin-top: 20px;
        width: calc(100% - 20px);
        max-width: 100%;
        font-size: 1rem;
    }
    height: 48px;
    border-radius: 500px;
    font-size: 20px;
    white-space: nowrap;
    font-family: Poppins;
    letter-spacing: 0.5px;
`

export default App;
