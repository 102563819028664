import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Feature, MapboxFeature, MapboxFeatureDictionary } from "common/feature";
import { getDocumentFromCollection } from "common/firestoreUtil";
import { getAllFeatures } from "common/functions";
import firebase from 'firebase/compat/app';

export const fetchMapboxFeatures = createAsyncThunk('map/fetchMapboxFeatures', async () => {
    const featureList = await getAllFeatures();
    let mapboxFeatureDictionary: MapboxFeatureDictionary = {}
    featureList.forEach((feature: MapboxFeature) => {
        if (!mapboxFeatureDictionary[feature.properties!.featureID]) {
            mapboxFeatureDictionary[feature.properties!.featureID] = feature;
        }
    });
    return mapboxFeatureDictionary
});

type SelectFeatureViaIDParams = {
    featureID?: string,
    segmentRouteState?: any[],
    unselectIfDuplicate?: boolean,
}

export const selectFeatureViaID = createAsyncThunk('map/selectFeatureViaID', async (params: SelectFeatureViaIDParams | undefined, { getState }) => {
    const state = getState() as any;
    const selectedFeatureData = state.map.selectedFeatureData;
    
    // if featureID is undefined, or if unselectIfDuplicate is true and featureID matches selected feature ID
    console.log(params?.featureID)
    if (!params || (params?.unselectIfDuplicate === true && params?.featureID === selectedFeatureData?.id)) {
        return undefined
    }
    
    const { featureID } = params;

    const firestoreDB = firebase.firestore();
    
    console.log(featureID)
    const document: Feature | undefined = await getDocumentFromCollection(firestoreDB, "betafeatures", featureID)
    // console.log(document)
    return {
        feature: document,
    };
});

type FetchRoutePathParams = {
    routeID: string,
    pathKey: string,
}

export const fetchRoutePath = createAsyncThunk('map/fetchRoutePath', async (params: FetchRoutePathParams | undefined, { getState }) => {
    console.log(params)
    const firestoreDB = firebase.firestore();
    // var docRef = firestoreDB.collection("betafeatures").doc(params?.routeID).collection('paths').doc(params?.pathKey);
    console.log('fetching')
    const document = await firestoreDB
      .collection('betafeatures') // Main collection
      .doc(params?.routeID)            // Document in the main collection
      .collection('paths') // Subcollection
      .doc(params?.pathKey)          // Document in the subcollection
      .get();
    console.log(document)

    if (document.exists) {
        console.log("Document data:", document.data());
        return document.data();
    } else {
        console.error("No such document in subcollection.");
    }
});

const mapSlice = createSlice({
    name: 'map',
    initialState: {
        selectedFeatureData: undefined,
        selectedSectionIndex: undefined,
        segmentRouteState: undefined,
        mapboxFeatures: undefined,
        zoom: false,
        pathData: undefined,
        highlightFeatureIDs: undefined,
        isLoadingPath: false,
    },
    reducers: {
        zoomToSelection: (state: any) => {
            state.zoom = !state.zoom
        },
        updateSegmentRouteState: (state: any, action) => {
            let segmentStateCopy = [...state.segmentRouteState]
            segmentStateCopy[action.payload.sectionIndex] = action.payload.optionIndex
            state.segmentRouteState = segmentStateCopy
        },
        updatePath: (state: any, action) =>{
            state.pathData = action.payload
        },
        updateSelectedSectionIndex: (state, action) => {
            state.selectedSectionIndex = action.payload
        },
        updateHighlightFeatureIDs: (state, action) => {
            state.highlightFeatureIDs = action.payload.highlightFeatureIDs
            state.zoom = action.payload.zoom || false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMapboxFeatures.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(fetchMapboxFeatures.fulfilled, (state: any, action) => {
                // state.status = 'succeeded';
                state.mapboxFeatures = action.payload;
            })
            .addCase(fetchMapboxFeatures.rejected, (state, action) => {
                // state.status = 'failed';
                // state.error = action.error.message;
            });
        builder
            .addCase(selectFeatureViaID.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(selectFeatureViaID.fulfilled, (state: any, action: any) => {
                state.selectedSectionIndex = undefined
                state.segmentRouteState = action.payload?.feature?.segmentStructure ? new Array(action.payload.feature.segmentStructure.length).fill(0) : undefined
                state.selectedFeatureData = action.payload?.feature
                state.highlightFeatureIDs = undefined
            })
            .addCase(selectFeatureViaID.rejected, (state, action) => {
                // state.status = 'failed';
                // state.error = action.error.message;
            });
        builder
            .addCase(fetchRoutePath.pending, (state) => {
                state.isLoadingPath = true;
            })
            .addCase(fetchRoutePath.fulfilled, (state: any, action: any) => {
                state.isLoadingPath = false;
                state.pathData = action.payload

            })
            .addCase(fetchRoutePath.rejected, (state, action) => {
                state.isLoadingPath = false;
                // state.error = action.error.message;
            });
    },
})

export const { updateSegmentRouteState, updateSelectedSectionIndex, zoomToSelection, updatePath, updateHighlightFeatureIDs } = mapSlice.actions;
export default mapSlice.reducer;