import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";

export const resendVerfication = createAsyncThunk('user/resendVerfication', async (params: any) => {
    params.sendEmailVerification();
    await sendEmailVerification(params)
        .then((res: any) => {
            console.log('sent verification email')
        })
        .catch((error: any) => {
            console.error(error)
        });
})

export const createAccount = createAsyncThunk('user/createAccount', async (params: { email: string, password: string }) => {
    const auth = getAuth();

    auth.onAuthStateChanged(function(user) {
        if (user) {
            //
        }
    });

    await createUserWithEmailAndPassword(auth, params.email, params.password)
        .then((userCredential: any) => {
            // Success
            console.log('created account')
            console.log(userCredential.user)
            // userCredential?.user?.sendEmailVerification()
            sendEmailVerification(userCredential.user)
                .then((res: any) => {
                    console.log('sent verification email')
                })
                .catch((error: any) => {
                    console.error(error)
                });
            // user = userCredential.user;
        })
        .catch((error: any) => {
            // Catch error
        });
});

export const resetPassword = createAsyncThunk('user/reset', async (params: { email: string }) => {
    const auth = getAuth();

    await sendPasswordResetEmail(auth, params.email)
        .then((res: any) => {
            // Signed in
            console.log(res)
        })
        .catch((error: any) => {
            // Catch error
        });
})

export const login = createAsyncThunk('user/login', async (params: { email: string, password: string }) => {
    const auth = getAuth();

    let user = undefined

    await signInWithEmailAndPassword(auth, params.email, params.password)
        .then((userCredential: any) => {
            // Signed in
            user = userCredential.user;
            console.log(user)
        })
        .catch((error: any) => {
            // Catch error
        });
        
    return user;
});

export const logout = createAsyncThunk('user/logout', async () => {
    const auth = getAuth();

    await signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });

    return true
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: undefined,
        isLoading: true,
    },
    reducers: {
        restoreUserSession: (state: any, action) => {
            state.user = action.payload
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state: any, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message;
            });
        builder
            .addCase(logout.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(logout.fulfilled, (state: any, action) => {
                // state.status = 'succeeded';
                if (action.payload === true) {
                    state.user = undefined;
                }
            })
            .addCase(logout.rejected, (state, action) => {
                // state.status = 'failed';
                // state.error = action.error.message;
            });
    },
})

export const { restoreUserSession } = userSlice.actions;
export default userSlice.reducer;