import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import mapboxgl, { MapMouseEvent } from 'mapbox-gl'

import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';
import { AnimatePresence } from 'framer-motion';

import { loadLayers, showLayer, dimLayer, getRouteIDfromPointFeature } from "common/functions"
import loopStyles from 'common/styles';
import { messages } from 'common/constants';
import { CSSPosition } from 'common/constants';
import { convertNumToIDNum, mapFirestoreObjectToArray, preventEventDefault } from 'common/utils';
import { createMap, desktopZoomSettings } from 'common/mapboxUtil';

import Button, { ButtonSize } from 'components/Button'
import MessageModal from 'components/MessageModal'
import { drawerWidth } from "components/RouteSidebar/RouteSidebar"
import Card from 'components/Card/Card';
import PageHeader from 'components/PageHeader';
import Sidebar from 'components/Sidebar/Sidebar';
import RouteSidebarLayout from 'components/Sidebar/layouts/RouteSidebarLayout/RouteSidebarLayout';
import FeatureLayout from 'components/Card/Layouts/FeatureLayout/FeatureLayout';
import { MapProps } from 'components/Map/constants';
import { fetchMapboxFeatures, selectFeatureViaID } from 'store/reducers/map';
import firebase from 'firebase/compat/app';
import { MapboxFeature } from 'common/feature';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

const FunctionalMap = (props: MapProps) => {
    // Redux state
    const dispatch = useDispatch();
    
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)
    const selectedSectionIndex = useSelector((state: any) => state.map.selectedSectionIndex)
    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const mapboxFeatures = useSelector((state: any) => state.map.mapboxFeatures)
    const filters = useSelector((state: any) => state.filters)

    const firestoreDB = firebase.firestore();

    //  Local state
    const [highlightedFeatureIDs, setHighlightedFeatureIDs] = useState<string[]>([])
    const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null)
    const [map, setMap] = useState<any>(undefined)
    const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false)
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(localStorage.getItem("isDisclaimerAccepted") === undefined ? true : Boolean(!localStorage.getItem("isDisclaimerAccepted")))

    const deselectAll = () => {
        dispatch(selectFeatureViaID(undefined))
        setHighlightedFeatureIDs([])
    }

    const acceptDisclaimer = () => {
        setIsDisclaimerOpen(false);
        localStorage.setItem("isDisclaimerAccepted", "true")
    }

    const resetView = () => {
        map && map.fitBounds([[160.24709996312168, -40.66482502632688], [-130, 67.66452943347628]])
    }

    const onMapLoad = async (mapInstance: mapboxgl.Map) => {

        const handleMapMouseMove = (e: MapMouseEvent) => {
            const hoveredFeatures = mapInstance.queryRenderedFeatures(e.point).filter((item: any) => item.layer.id === 'allfeatures' || item.layer.id === 'points' || item.layer.id === 'teams' || item.layer.id === 'selectedteams');
            mapInstance.getCanvas().style.cursor = hoveredFeatures.length ? 'pointer' : '';
    
            const hoveredFeatureIDs = hoveredFeatures.map((feature: any) => feature.properties.featureID.split('_').slice(0, 2).join('_'))
            setHighlightedFeatureIDs(hoveredFeatureIDs)
        }
    
        const handleMapMouseClick = (e: MapMouseEvent) => {
            const features = mapInstance.queryRenderedFeatures(e.point).filter((item: any) => item.layer.id === 'allfeatures' || item.layer.id === 'points' || item.layer.id === 'teams' || item.layer.id === 'selectedteams');
        
            if (features.length) {
                dispatch(selectFeatureViaID(features[0].properties!.featureID.split('_').slice(0, 2).join('_')))
                // fetch selectedFeatureData
                //   this.setState({ selectedFeatureID: features[0].properties.featureID }, updateRouteDisplay);
            }
        }

        // push selected teams to top of the z-index
        mapInstance.moveLayer('teams', 'selectedteams');
        mapInstance.on('mousemove', 'allfeatures', handleMapMouseMove);
        mapInstance.on('mouseleave', 'allfeatures', handleMapMouseMove);
        mapInstance.on('mousemove', 'teams', handleMapMouseMove);
        mapInstance.on('mouseleave', 'teams', handleMapMouseMove);
        mapInstance.on('mousemove', 'selectedteams', handleMapMouseMove);
        mapInstance.on('mouseleave', 'selectedteams', handleMapMouseMove);
        mapInstance.on('click', handleMapMouseClick);
        mapInstance.fitBounds([[160.24709996312168, -40.66482502632688], [-130, 67.66452943347628]])
        setIsMapStyleLoaded(true)
    }

    useEffect(() => {
        if (isMapStyleLoaded && map.getLayer('allfeatures') === undefined) {
            mapboxFeatures && map && loadLayers(map, Object.values(mapboxFeatures))
        }
    }, [mapboxFeatures, isMapStyleLoaded]) // eslint-disable-line

    const zoomToRoute = (route: any) => {
        if (!route) return;
    
        const coordinates = route.geometry.coordinates.reduce((accumulator: any, linestring: any) => [...accumulator, ...linestring], [])
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        );
           
        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
          bounds.extend(coord);
        }
           
        map?.fitBounds(bounds, desktopZoomSettings);
    }

    const autoZoom = (featureID: any) => {
        if (mapboxFeatures[featureID]?.geometry.type === 'Point') {
          map.setZoom(10.0)
          map.flyTo({ center: mapboxFeatures[featureID]?.geometry.coordinates })
        } else {
          selectedFeatureData?.isSegmentRoute === true ? map?.fitBounds(
            mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.route), desktopZoomSettings
          ) : zoomToRoute(mapboxFeatures[featureID]);
        }    
    }

    useEffect(() => {
        if (selectedFeatureData) {
            autoZoom(selectedFeatureData.featureID)
        }
    }, [selectedFeatureData]) // eslint-disable-line

    useEffect(() => {
        if (selectedSectionIndex !== undefined) {
            map.fitBounds(
                mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.sections[selectedSectionIndex]), 
                desktopZoomSettings
            )
        } else if (map && selectedFeatureData) {
            autoZoom(selectedFeatureData.featureID)
        }
    }, [selectedSectionIndex]) // eslint-disable-line

    const getHighlightedPointFeatures = (highlightIDs: any[]) => {

        // map selected options to the station IDs associated with that option, then reduce to a 1D array of station IDs
        const activeSegmentStationIDs = segmentRouteState?.map((optionValue: number, sectionIndex: number) => selectedSectionIndex === undefined || selectedSectionIndex === sectionIndex ? selectedFeatureData?.segmentData[sectionIndex][optionValue].stationIDs : [])
            .reduce((prev: any[], current: any[]) => current?.length ? [...prev, ...current] : prev, [])
        // list of all mapbox point features
        const points = Object.values(mapboxFeatures).filter((feature: any) => feature.geometry.type === "Point")
        
        const filterHighlightedPoints = ((point: any) => {
            const isActiveSegmentStation = !!activeSegmentStationIDs?.length && activeSegmentStationIDs.indexOf(point.properties!.featureID) > -1
            const isHighlightedPoint = !selectedFeatureData?.isSegmentRoute && getRouteIDfromPointFeature(point) && highlightIDs.indexOf(getRouteIDfromPointFeature(point)) > -1
            const isHiddenTeam = point.properties!.companyID === 'AHT' && filters.teams

            return !isHiddenTeam && (isActiveSegmentStation || isHighlightedPoint)
        })

        return points.filter(filterHighlightedPoints)
    }

    const getHighlightedRouteFeatures = (highlightIDs: string[]) => {
        // map segment route state to the feature ID of each selected option
        const activeSegmentIDs = segmentRouteState?.map((optionValue: number, sectionIndex: number) => `${selectedFeatureData?.featureID}_s${convertNumToIDNum(sectionIndex + 1)}_${convertNumToIDNum(optionValue + 1)}`)
            .filter((segmentID: string, sectionIndex: number) => selectedSectionIndex === undefined || selectedSectionIndex === sectionIndex)

        const getParentID = (r: any) => r.properties?.featureID.split('_').slice(0, 2).join('_')
        const filterActiveOptionRoutes = (route: any) => {
            // if it's an option route, do not filter
            if (route.properties?.featureID.split('_').length !== 4) {
                return true;
            }

            if (route.properties.featureID === selectedFeatureData?.featureID) {
                return true;
            }

            // if the option is part of the currently selected route
            if (getParentID(route) === selectedFeatureData?.featureID) {
                return activeSegmentIDs.includes(route.properties.featureID)
            }

            const [sectionNumber, optionNumber] = route.properties?.featureID.split('_').slice(2).map((str: string) => Number(str)) // eslint-disable-line
            return optionNumber === 1
        }

        
        // array of all mapbox route features
        const routes = Object.values(mapboxFeatures).filter((feature: any) => feature.geometry.type !== "Point")

        const filteredCompanyRoutes = filters.company.length ? routes.filter((route: any) => filters.company.includes(route.properties.companyID)) : routes;
        const filteredStatusRoutes = filters.status.length ? filteredCompanyRoutes.filter((route: any) => filters.status.includes(route.properties.status)) : filteredCompanyRoutes;

        const highlightedRoutes = routes.filter((route: any) => highlightIDs.indexOf(getParentID(route)) > -1)

        const selectedRoutes = routes.filter((route: any) => getParentID(route) === selectedFeatureData?.featureID)
        const displayRoutes = [...(selectedRoutes.length ? selectedRoutes : filteredStatusRoutes), ...highlightedRoutes].filter(filterActiveOptionRoutes)
        return displayRoutes
    }

    // Update route display
    useEffect(() => {
        if (!mapboxFeatures) return;

        const highlightIDs = [...highlightedFeatureIDs]
        selectedFeatureData && highlightIDs.push(selectedFeatureData.featureID)

        const displayPoints = getHighlightedPointFeatures(highlightIDs);
        const displayCities = (displayPoints as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID !== 'AHT')
        const displayTeams = (displayPoints as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT')
        const displayFeatures = getHighlightedRouteFeatures(highlightIDs);

        map?.getSource('selectedfeatures')?.setData({
            "type": "FeatureCollection",
            "features": displayFeatures
        })

        // map.resize();
        if (displayFeatures.length === 0 && displayPoints.length === 0 && filters.company.length === 0) {
            showLayer(map, 'allfeatures')
            showLayer(map, 'teams')

            map?.getSource('points').setData({
                type: "FeatureCollection",
                features: []
            })
            map?.getSource('citynames').setData({
                type: "FeatureCollection",
                features: []
            })
            map?.getSource('teams').setData({
                type: "FeatureCollection",
                features: filters.teams ? [] : (Object.values(mapboxFeatures) as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT')
            })
            map?.getSource('selectedteams').setData({
                type: "FeatureCollection",
                features: []
            })
        } else {
            dimLayer(map, 'allfeatures');
            dimLayer(map, 'teams');

            map?.getSource('points').setData({
                "type": "FeatureCollection",
                "features": displayCities
            })
            map?.getSource('citynames').setData({
                "type": "FeatureCollection",
                "features": displayCities
            })
            map?.getSource('teams').setData({
                type: "FeatureCollection",
                features: filters.teams ? [] : (Object.values(mapboxFeatures) as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT' && !displayTeams.some(team => team.properties!.featureID === feature.properties!.featureID))
            })
            map?.getSource('selectedteams').setData({
                type: "FeatureCollection",
                features: displayTeams,
            })
        }
    }, [selectedFeatureData, selectedSectionIndex, segmentRouteState, filters, highlightedFeatureIDs]) // eslint-disable-line

    const initMapContainer = async (el: HTMLDivElement | null) => {
        setMapContainer(el);
    }

    const initMap = async () => {
        let mapInstance = await createMap(mapContainer, false);
        setMap(mapInstance)

        mapInstance.on('load', async () => {
            onMapLoad(mapInstance);
        })
    }

    useEffect(() => {
        mapContainer && initMap();
    }, [mapContainer]) // eslint-disable-line

    const getLocationAndGo = () => {
        if (navigator.geolocation) {
          // TODO: Add error callback so user knows the button isn't just broken
          navigator.geolocation.getCurrentPosition((position: any) => {
            map.setZoom(10.0);
            map.flyTo({ center: [position.coords.longitude, position.coords.latitude] })
          }, () => {}, {timeout:10000});
        }
    }

    useEffect(() => {
        dispatch(fetchMapboxFeatures())
    }, []) // eslint-disable-line

    return <Container>
        <PageHeader
            deselectAll={deselectAll}
            showViewResetButton={true}
            resetView={resetView}
        />
        <div style={{display: 'flex', flex: '1 0 auto', justifyContent: 'stretch'}}>
            <Sidebar isOpen={true}>
                <RouteSidebarLayout 
                    firestoreDB={firestoreDB} 
                    resetView={resetView}
                />
            </Sidebar>
            <MapContainer ref={initMapContainer} onClick={preventEventDefault}>
                {map && isDisclaimerOpen && <DisclaimerContainer>
                    <MessageModal label={messages.Disclaimer} callback={acceptDisclaimer} />
                </DisclaimerContainer>}
            </MapContainer>
            {/* {showLogin && <Card style={{top: '85px', right: `10px`}}>
            <div id="firebaseui-auth-container" style={{ height: 'auto', width: 'auto' }}/>
            </Card>} */}
            <AnimatePresence exitBeforeEnter>
                {selectedFeatureData && <Card 
                    style={{top: '85px', right: `10px`}} 
                    initial={{opacity: 0, x: 400}} 
                    exit={{opacity: 0, x: 400}} 
                    animate={{opacity: 1, x: 0}}
                >
                    <FeatureLayout
                        feature={selectedFeatureData}
                    />
                </Card>}
            </AnimatePresence>
        </div>
        {map && <LocateMeButton>
            <Button
                size={ButtonSize.Large}
                icon='Location.png'
                label='Locate Me'
                onClick={getLocationAndGo}
            />
        </LocateMeButton>}
    </Container>
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: 'auto',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  backgroundColor: 'rgba(35, 34, 36, 0.9)',
})

const DisclaimerContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  flex: '1',
  width: '100%',
  justifyContent: 'center',
  bottom: '70px'
})

const MapContainer = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  flex: '1',
  outline: 'none'
})

const LocateMeButton = styled.div({
  position: 'absolute',
  top: '85px',
  left: `${drawerWidth + 10}px`,
  zIndex: 1,
})

const styles = {
  title: {
    color: loopStyles.colors.tertiary,
    fontFamily: 'OneDay',
    marginLeft: '30px'
  },
  sidebarCloseIcon: {
    position: CSSPosition.Absolute,
    left: '12px',
  },
  sidebarOpenIcon: {
    position: CSSPosition.Absolute,
    left: '9px',
  },
  sidebarButton: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: loopStyles.colors.tertiary,
    width: '40px',
    height: '40px',
  },
}

export default withStyles(styles)(FunctionalMap);