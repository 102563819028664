import loopStyles from "common/styles";
import Sidebar from "components/common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TuneIcon from '@mui/icons-material/Tune';
import SidebarGroup from "./RouteSidebarGroup";
import { useState } from "react";
import Language from '@material-ui/icons/Language';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { AnimatePresence, motion } from "framer-motion";
import TeamSidebarGroup from "./TeamSidebarGroup";
import { toggleFilterMenu, updateFilter } from "store/reducers/filters";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import QuizIcon from '@mui/icons-material/Quiz';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CircularProgress from '@mui/material/CircularProgress';

type RouteSidebarProps = {
    toggleSidebar: () => void,
    isOpen: boolean,
    resetView: () => void,
    toggleAboutUs: () => void,
    toggleFAQ: () => void,
    toggleContact: () => void,
}

const statuses = [
    'Concept',
    'Proposal',
    'Approval',
    'Construction',
    'Operational',
    'On Hold',
    'Canceled',
]

const RouteSidebar = (props: RouteSidebarProps) => {
    const dispatch = useDispatch()

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const menuData = useSelector((state: any) => state.menu.data)
    const filters = useSelector((state: any) => state.filters)
    const isMenuLoading = useSelector((state: any) => state.menu.isLoading)

    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const [isHyperloopsOpen, setIsHyperloopsOpen] = useState(true)
    const [isLoopsOpen, setIsLoopsOpen] = useState(false)
    const [isTeamsOpen, setIsTeamsOpen] = useState(false)

    const activeFilters = Object.keys(filters.company).length + Object.keys(filters.status).length + (filters.teams ? 0 : 1)

    const filterCompany = (company: any) => {
        if (filters.company.length > 0 && (!filters.company.includes(company.companyID) && !filters.company.includes(company.featureID))) {
            return false
        }

        if (company.points?.length === 0 && company.routes?.length === 0) {
            return false
        }

        return true
    }

    const generateFilterChangeHandler = (key: string) => {
        return (ev: any) => {
            const copyOfFilter = new Set(filters[key])
            copyOfFilter.has(ev.currentTarget.id) ? copyOfFilter.delete(ev.currentTarget.id) : copyOfFilter.add(ev.currentTarget.id)
            console.log(key, copyOfFilter)
            dispatch(updateFilter({key, filter: Array.from(copyOfFilter)}))
        }
    }

    const { toggleSidebar, isOpen, resetView, toggleAboutUs, toggleFAQ, toggleContact } = props;

    return <Sidebar fullscreen={isMobile} onClose={toggleSidebar} isOpen={!isMobile || isOpen}>
        <div style={{borderRight: '2px solid rgb(51, 50, 51)', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: 'calc(100% - 2px)', display: 'flex', justifyContent: 'center', margin: '1px 1px', padding: '12px 0', borderLeft: `1px solid rgb(51, 50, 51)`, borderRight: `1px solid rgb(51, 50, 51)`, borderRadius: '0 0 4px 4px', borderBottom: `1px solid rgb(51, 50, 51)`}}>
                <IconButton onClick={toggleFAQ}>
                    <QuizIcon/>
                </IconButton>
                <IconButton onClick={toggleAboutUs}>
                    <InfoIcon/>
                </IconButton>
                <IconButton onClick={toggleContact}>
                    <AlternateEmailIcon/>
                </IconButton>
                <IconButton onClick={resetView}>
                    <StyledLanguageIcon/>
                </IconButton>
                <div style={{position: 'relative'}}>
                    <IconButton active={filters.isOpen} onClick={() => { dispatch(toggleFilterMenu()); toggleSidebar(); }}>
                        <StyledTuneIcon/>
                    </IconButton>
                    <AnimatePresence exitBeforeEnter>
                        {!!activeFilters && <motion.div
                            {...loopStyles.motion.fadeInOut}
                            style={{ 
                                position: 'absolute',
                                fontSize: '14px',
                                backgroundColor: 'rgba(0,0,0,1)',
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: -10,
                                right: -5,
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                border: `1px solid ${loopStyles.colors.tertiary}`,
                            }}
                        >
                            {activeFilters}
                        </motion.div>}
                    </AnimatePresence>
                </div>
            </div>
            <Scrollable>
                <AnimatePresence exitBeforeEnter>
                    {isMenuLoading ? <motion.div 
                        {...loopStyles.motion.fadeInOut}
                        key='loading'
                        style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <CircularProgress style={{color: loopStyles.colors.tertiary}}/>
                    </motion.div> : <motion.div
                        {...loopStyles.motion.fadeInOut}
                        key='content'
                    >
                        <GroupTitle isOpen={isHyperloopsOpen} onClick={() => { setIsHyperloopsOpen(!isHyperloopsOpen) }}>
                            <span>Hyperloop</span>
                            {isHyperloopsOpen ? <ExpandMore /> : <ExpandLess />}
                        </GroupTitle>
                        <AnimatePresence exitBeforeEnter>
                            {menuData && isHyperloopsOpen && <motion.div 
                                initial={{ height: 0, opacity: 0 }}
                                exit={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                transition={{ duration: 0.2, type: "tween" }}
                                style={{ borderRadius: '0 4px 4px 0', borderLeft: `1px solid ${loopStyles.colors.tertiary}`, overflow: 'hidden'}}
                            >
                                <div style={{padding: '8px 0'}}>
                                    {Object.values(menuData!).filter(filterCompany).filter((company: any) => company?.techType === 'HyperLoop').sort((a: any,b: any) => Number(a.menuSortOrder) - Number(b.menuSortOrder)).map((company: any) =>  <SidebarGroup key={company.name} company={company}/>)}
                                </div>
                            </motion.div>}
                        </AnimatePresence>
                        <GroupTitle isOpen={isLoopsOpen} onClick={() => { setIsLoopsOpen(!isLoopsOpen) }}> 
                            <span>Loop</span>
                            {isLoopsOpen ? <ExpandMore /> : <ExpandLess />}
                        </GroupTitle>
                        <AnimatePresence exitBeforeEnter>
                            {menuData && isLoopsOpen && <motion.div 
                                initial={{ height: 0, opacity: 0 }}
                                exit={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                transition={{ duration: 0.2, type: "tween" }}
                                style={{ borderRadius: '0 4px 4px 0', borderLeft: `1px solid ${loopStyles.colors.tertiary}`, overflow: 'hidden'}}
                            >
                                <div style={{padding: '8px 0'}}>
                                    {Object.values(menuData!).filter(filterCompany).filter((company: any) => company?.techType === 'Loop').sort((a: any,b: any) => Number(a.menuSortOrder) - Number(b.menuSortOrder)).map((company: any, index: number) =>  <SidebarGroup key={company.name} company={company}/>)}
                                </div>
                            </motion.div>}
                        </AnimatePresence>
                        <GroupTitle isOpen={isTeamsOpen} onClick={() => { setIsTeamsOpen(!isTeamsOpen) }}>
                            <span>Academic Teams</span>
                            {isTeamsOpen ? <ExpandMore /> : <ExpandLess />}
                        </GroupTitle>
                        <AnimatePresence exitBeforeEnter>
                            {menuData?.teams && isTeamsOpen && <motion.div 
                                initial={{ height: 0, opacity: 0 }}
                                exit={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                transition={{ duration: 0.2, type: "tween" }}
                                style={{ borderRadius: '0 4px 4px 0', borderLeft: `1px solid ${loopStyles.colors.tertiary}`, overflow: 'hidden'}}
                            >
                                <div style={{padding: '8px 0'}}>
                                    {Object.keys(menuData?.teams).map((area: string) => <TeamSidebarGroup key={area} name={area} teams={menuData?.teams[area]}/>)}
                                </div>
                            </motion.div>}
                        </AnimatePresence>
                    </motion.div>}
                </AnimatePresence>
            </Scrollable>
        </div>
    </Sidebar>
}

const StyledExpandMore = styled(ExpandMore)`

`

const StyledExpandLess = styled(ExpandLess)`

`

const FilterRow = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    ${loopStyles.transition.animationStyles}
    &:hover {
        background-color: rgba(255,255,255,0.1);
    }
`

const CheckboxLabel = styled.div`
    color: ${loopStyles.colors.secondary};
    flex: 1;
`

const StyledRemoveIcon = styled(RemoveIcon)`
    width: 20px;
    height: 20px;
`

const StyledAddIcon = styled(AddIcon)`
    width: 20px;
    height: 20px;
`

const CollapseButton = styled.div`
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all 0.2s ease-in;
    &:hover {
        background-color: ${loopStyles.colors.tertiary};
        color: ${loopStyles.colors.primary};
    }
`

type IconButtonProps = {
    selected?: boolean,
    active?: boolean,
}

const IconButton = styled.div<IconButtonProps>`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    font-family: Poppins;
    white-space: nowrap;
    color: ${props => props.active ? '#fff' : '#B5B5BE'};
    padding: 10px;
    margin: 0 5px;
    border-radius: 8px;
    background-color: ${props => props.active ? 'rgba(0,0,0,0.6)' : 'transparent'};
    border: 1px solid ${props => props.active ? loopStyles.colors.tertiary : 'transparent'};
    ${loopStyles.transition.animationStyles}
    ${props => props.selected && 'background-color: rgba(0,0,0,0.2);'}
    &:hover {
        cursor: pointer;
        color: #fff;
        border: 1px solid ${loopStyles.colors.tertiary};
        background-color: rgba(0,0,0,0.6);
    }
`

const Scrollable = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
    user-select: none;
    ${loopStyles.scrollbar.default}
`

const FilterButtonLabel = styled.div`
`

const StyledLanguageIcon = styled(Language)`
    border-radius: 50%;
    ${loopStyles.transition.animationStyles}
`

const StyledTuneIcon = styled(TuneIcon)`
    border-radius: 50%;
    ${loopStyles.transition.animationStyles}
`

type GroupTitleProps = {
    isOpen: boolean
}

const GroupTitle = styled.div<GroupTitleProps>`
    border-left: 1px solid ${loopStyles.colors.tertiary};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 12px;
    padding-bottom: 10px;
    border-radius: 0 4px 4px 0;
    color: ${loopStyles.colors.tertiary};
    font-family: Poppins;
    font-style: normal;
    font-weight: ${props => props.isOpen ? 600 : 400};
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    &:not(&:first-child) {
        margin-top: 10px;
    }
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: rgba(0,0,0,0.15);
    }
    &:active {
        background-color: rgba(0,0,0,0.25);
    }
    -moz-transition: background-color .2s ease-in;
    -o-transition: background-color .2s ease-in;
    -webkit-transition: background-color .2s ease-in;
    transition: background-color .2s ease-in;
`

type ListItemIconContainerProps = {
    color?: string,
}

const ListItemIconContainer = styled.div<ListItemIconContainerProps>`
    color: ${props => props.color ? props.color : loopStyles.colors.tertiary};
    width: 36px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default RouteSidebar