import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled, { keyframes } from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import YouTubeVideo from 'components/common/YouTubeVideo';
import LinkIcon from '@mui/icons-material/Link';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CircularProgress from '@mui/material/CircularProgress';

// import { ZoomInIcon } from '@material-ui/icons/ZoomIn';
import { ZoomIn as ZoomInIcon, CenterFocusWeak as CenterFocusWeakIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoutePath, updatePath, updateSegmentRouteState, updateSelectedSectionIndex, zoomToSelection, updateHighlightFeatureIDs } from 'store/reducers/map';
import { AnimatePresence, motion } from 'framer-motion';

enum TabKey {
    Info,
    Options,
    Paths,
}

interface RouteLayoutProps {
    feature: Feature,
    toggleExpanded: () => void,
    classes: any,
}

const RouteLayout = (props: RouteLayoutProps) => {
    // Redux state
    const dispatch = useDispatch();

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const pathData = useSelector((state: any) => state.map.pathData)
    const isLoadingPath = useSelector((state: any) => state.map.isLoadingPath)

    // Local State
    const [tabIndex, setTabIndex] = useState(0)
    const [tab, setTab] = useState(TabKey.Paths)
    const [isImperialUnits, setIsImperialUnits] = useState(true)
    const [departureStationID, setDepartureStationID] = useState<any>(undefined)
    const [arrivalStationID, setArrivalStationID] = useState<any>(undefined)

    const { feature, classes } = props;

    const distanceKey = isImperialUnits ? 'distanceMI' : 'distanceKM'
    const avgSpeedKey = isImperialUnits ? 'avgSpeedMPH' : 'avgSpeedKMH'
    const topSpeedKey = isImperialUnits ? 'topSpeedMPH' : 'topSpeedKMH'

    const distance = feature && feature[distanceKey] !== "" ? `${feature[distanceKey]} ${isImperialUnits ? 'mi' : 'km'}` : '—'
    const avgSpeed = feature && feature[avgSpeedKey] !== "" ? `${feature[avgSpeedKey]} ${isImperialUnits ? 'mph' : 'kmh'}` : '—'
    const travelTime = feature && feature.travelTime !== "" ? `${feature.travelTime} ` : '—'
    const topSpeed = feature && feature[topSpeedKey] !== "" ? `${feature[topSpeedKey]} ${isImperialUnits ? 'mph' : 'kmh'}` : '—'

    const selectedChildFeatureIDs = segmentRouteState
        ?.map((optionValue: number, sectionIndex: number) => Array.from(new Set(feature.segmentData[sectionIndex][optionValue]?.childFeatures)))
        ?.flat()
        ?.filter((featureID: string) => !featureID.includes('_L'))

    const isStatsEmpty = [distance, avgSpeed, travelTime, topSpeed].every(detail => detail === '—')
    const isDetailsEmpty = isStatsEmpty && (feature?.description === '' || feature?.description === ' ') && !feature.sourceUrl
    const isOptionsEmpty = !(!!feature?.segmentStructure && !(feature?.segmentStructure.length === 1 && [0,1].includes(feature?.segmentStructure[0])))
    const isPathsEmpty = selectedChildFeatureIDs?.length <= 1
    console.log(isPathsEmpty, isDetailsEmpty, isOptionsEmpty)
    console.log(selectedChildFeatureIDs)
    console.log(selectedChildFeatureIDs?.length > 1)

    const kmToMi = (kilometers: number) => {
        return kilometers * 0.621371
    }

    const stringifyHours = (totalHours: number) => {
        const hours = Math.floor(totalHours)
        const minutes = Math.floor((totalHours % 1) * 60)

        const hoursString = hours ? `${hours} hr${hours > 1 ? 's' : ''}` : ''
        const minString = minutes ? `${minutes} min` : ''

        return !hours && !minutes ? '-' : `${hoursString} ${minString}`
    }

    useEffect(() => {
        // setTabIndex(0)
        setTab(isPathsEmpty ? (isDetailsEmpty ? TabKey.Options : TabKey.Info) : TabKey.Paths)
        dispatch(updatePath(undefined))
        if (selectedChildFeatureIDs?.length === 2) {
            setDepartureStationID(selectedChildFeatureIDs[0])
            setArrivalStationID(selectedChildFeatureIDs[1])
        } else {
            setDepartureStationID(undefined)
            setArrivalStationID(undefined)
        }
    }, [props.feature]) // eslint-disable-line

    useEffect(() => {
        if (arrivalStationID && departureStationID) {
            const key = [departureStationID, arrivalStationID].sort((aid, bid) => Number(aid.split('_')[2]) - Number(bid.split('_')[2])).join('+')
            const stateKey = segmentRouteState.map((val: number) => val + 1).join('')
            dispatch(fetchRoutePath({
                routeID: props.feature.featureID,
                pathKey: `${stateKey}_${key}`
            }))
        }
    }, [arrivalStationID, departureStationID]) // eslint-disable-line

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    useEffect(() => {
        if (tab !== TabKey.Paths) {
            dispatch(updatePath(undefined))
            setDepartureStationID(undefined)
            setArrivalStationID(undefined)
        }
    }, [tab])

    const getTabContent = (tabIndex: number) => {
        const { feature, toggleExpanded } = props;

        if (feature === undefined) { return }

        let content = undefined
        let adjustedTabIndex = tabIndex + (isDetailsEmpty ? 1 : 0)

        switch (tab) {
            case TabKey.Info:
                content = <DetailsContainer>
                    {feature?.youtube && <div key={feature?.featureID} style={{ height: '225px' }}>
                        <YouTubeVideo link={feature?.youtube} />
                    </div>}
                    {!isDetailsEmpty ? <>
                        {!isStatsEmpty && <div style={{display: 'flex', width: '100%', textAlign: 'center', color: '#FFFFFF', margin: '20px 0'}}>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Distance</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{distance}</div>
                            </div>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Top Speed</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{topSpeed}</div>
                            </div>
                            <div style={{width: '25%', borderRight: '1px solid #B7B7B7'}}>
                                <div>Avg Speed</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{avgSpeed}</div>
                            </div>
                            <div style={{width: '25%'}}>
                                <div>Time</div>
                                <div style={{fontSize: '14px', color: '#aaa'}}>{travelTime}</div>
                            </div>
                        </div>}
                        {feature.sourceUrl && <div style={{ display: 'flex', alignItems: 'center', margin: '10px 20px', overflow: 'hidden'}}>
                            <LinkIcon style={{ color: loopStyles.colors.secondary, marginRight: '10px' }}/>
                            <a href={feature.sourceUrl} rel='noreferrer' target='_blank' style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '23px',
                                letterSpacing: '0.1px',
                                color: loopStyles.colors.tertiary,
                                // textWrap: 'wrap',
                                // wordBreak: 'break-all',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{feature.sourceUrl}</a>
                        </div>}
                        {feature.description && <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '23px',
                            letterSpacing: '0.1px',
                            color: '#B7B7B7',
                            margin: '10px 20px',
                            overflow: 'hidden',
                        }}>
                            {feature.description}
                        </div>}
                    </> : <EmptyDetailsLabel>
                        Details for this route aren't currently available.
                    </EmptyDetailsLabel>}
                </DetailsContainer>
                break;
            case TabKey.Options:
                content = feature?.segmentStructure && segmentRouteState && <OptionsContainer>
                    {feature.segmentStructure.map((sectionLength: any, sectionIndex: number) => {
                        const segmentFeature = feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]

                        const distance = isImperialUnits ? `${segmentFeature?.distanceMI === '' ? '—' : segmentFeature?.distanceMI} mi` : `${segmentFeature.distanceKM === '' ? '—' : segmentFeature.distanceKM} km`
                        const topSpeed = isImperialUnits ? `${segmentFeature?.topSpeedMPH === '' ? '—' : segmentFeature?.topSpeedMPH} mph` : `${segmentFeature.topSpeedKMH === '' ? '—' : segmentFeature.topSpeedKMH} kmh`
                        const avgSpeed = isImperialUnits ? `${segmentFeature?.avgSpeedMPH === '' ? '—' : segmentFeature?.avgSpeedMPH} mph` : `${segmentFeature.avgSpeedKMH === '' ? '—' : segmentFeature.avgSpeedKMH} kmh`
                        const travelTime = segmentFeature?.travelTime === '' ? '— min' : segmentFeature?.travelTime
                        const isOptionDataEmpty = [distance, topSpeed, avgSpeed, travelTime].every(unit => unit.includes('—'))

                        return <div key={`segment:${sectionIndex}`} style={{ width: 'calc(100% - 40px)', padding: '10px 20px', borderTop: sectionIndex > 0 ? `1px solid ${loopStyles.colors.secondary}` : '' }}>
                            <div style={{
                                fontFamily: 'Poppins',
                                marginBottom: '8px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                fontSize: '18px',
                                lineHeight: '18px',
                                letterSpacing: '0.1px',
                                color: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textOverflow: 'ellipsis'
                            }}>
                                {feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.name}
                                <div style={{ display: 'flex' }}>
                                    <ZoomButton onClick={() => { 
                                        dispatch(updateSelectedSectionIndex(sectionIndex))
                                        // dispatch(updateHighlightFeatureIDs({
                                        //     highlightFeatureIDs: feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.childFeatures,
                                        // }))
                                        isMobile && toggleExpanded()
                                    }}>
                                        <CenterFocusWeakIcon />
                                    </ZoomButton>
                                    <ZoomButton onClick={() => {
                                        dispatch(updateSelectedSectionIndex(sectionIndex));
                                        // dispatch(updateHighlightFeatureIDs({
                                        //     highlightFeatureIDs: feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.childFeatures,
                                        // }))
                                        dispatch(zoomToSelection())
                                        isMobile && toggleExpanded()
                                    }}>
                                        <ZoomInIcon />
                                    </ZoomButton>
                                </div>
                            </div>
                            {sectionLength > 1 && <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-around',
                                margin: '20px 0',
                            }}>
                                {(new Array(sectionLength).fill(0)).map((option, optionIndex) => 
                                    <OptionBubble 
                                        selected={segmentRouteState && optionIndex === segmentRouteState[sectionIndex]} 
                                        onClick={() => {
                                            dispatch(updateSegmentRouteState({sectionIndex, optionIndex}));
                                            dispatch(updatePath(undefined));
                                        }}
                                    >
                                </OptionBubble>)}
                            </div>}
                            {!isOptionDataEmpty && <div style={{display: 'flex', width: '100%', textAlign: 'center', color: '#FFFFFF', margin: '20px 0'}}>
                                <div style={{width: '25%', borderRight: '1px solid rgba(255,255,255,0.2)'}}>
                                    <div>Distance</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{distance}</div>
                                </div>
                                <div style={{width: '25%', borderRight: '1px solid rgba(255,255,255,0.2)'}}>
                                    <div>Top Speed</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{topSpeed}</div>
                                </div>
                                <div style={{width: '25%', borderRight: '1px solid rgba(255,255,255,0.2)'}}>
                                    <div>Avg Speed</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{avgSpeed}</div>
                                </div>
                                <div style={{width: '25%'}}>
                                    <div>Time</div>
                                    <div style={{fontSize: '14px', color: "#aaa"}}>{travelTime}</div>
                                </div>
                            </div>}
                            <div style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '23px',
                                letterSpacing: '0.1px',
                                color: '#B7B7B7',
                            }}>
                                {feature.segmentData[sectionIndex][segmentRouteState[sectionIndex]]?.description}
                            </div>
                        </div>}
                    )}
                </OptionsContainer>
                break;
            case TabKey.Paths:
                const pathIDs = pathData?.ids?.split(',')
                const stationIDs = pathIDs?.filter((s: string) => !s.includes('_L'))
                // Multiple by constant to convert from KM to MI
                const distanceMI = kmToMi(pathData?.distanceKM)
                // Divide by avg Hyperloop speed to get travel time
                const calcTravelTime = distanceMI / 386
                const planeTravelTime = distanceMI / 175
                const carTravelTime = distanceMI / 60

                return <PathsContainer>
                    <div style={{width: '100%', display: 'flex', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderBottom: '1px solid #454545', borderLeft: '1px solid #454545', borderRight: '1px solid #454545', borderRadius: '0 0 16px 16px', paddingBottom: '10px'}}>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{fontSize: '14px', fontFamily: 'Poppins', color: loopStyles.colors.tertiary}}>Departure</div>
                            <StationSelect value={departureStationID} onChange={(ev: any) => { console.log(ev.target.value); setDepartureStationID(ev.target.value) }}>
                                <option style={{color: 'black'}} value="" selected disabled>Departure Station</option>
                                {selectedChildFeatureIDs
                                    ?.filter((id: string) => id !== arrivalStationID && feature.stationNames[id])
                                    .map((id: string) =>  <option style={{color: 'black'}} value={id}>{feature.stationNames[id]}</option>)
                                }
                            </StationSelect>
                        </div>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{fontSize: '14px', fontFamily: 'Poppins', color: loopStyles.colors.tertiary}}>Arrival</div>
                            <StationSelect value={arrivalStationID} onChange={(ev: any) => { setArrivalStationID(ev.target.value) }}>
                                <option style={{color: 'black'}} value="" selected disabled>Arrival Station</option>
                                {selectedChildFeatureIDs
                                    ?.filter((id: string) => id !== departureStationID && feature.stationNames[id])
                                    .map((id: string) =>  <option style={{color: 'black'}} value={id}>{feature.stationNames[id]}</option>)
                                }
                            </StationSelect>
                        </div>
                    </div>
                    <PathList>
                        {/* <AnimatePresence exitBeforeEnter> */}
                            {isLoadingPath && <motion.div 
                                style={{maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', maxHeight: '100%'}}
                                initial={{opacity: 0}}
                                exit={{opacity: 0}}
                                animate={{opacity: 1}}
                                key='loading'
                            >
                                <CircularProgress style={{color: loopStyles.colors.tertiary}}/>
                            </motion.div>}
                            {!isLoadingPath && ((departureStationID && arrivalStationID) ? <motion.div 
                                style={{maxWidth: '100%', textWrap: 'wrap', wordWrap: 'break-word', padding: '10px 20px', height: 'min-content', maxHeight: '100%'}}
                                initial={{opacity: 0}}
                                exit={{opacity: 0}}
                                animate={{opacity: 1}}
                                key='content'
                            >
                                {!!pathData?.ids ? <div>
                                    <div style={{display: 'flex', width: '100%', userSelect: 'none'}}>
                                        <div style={{flex: '2 0 0'}}>
                                            <div style={{ color: '#B5B5BE', fontFamily: 'Poppins', opacity: 0.5 }}>Distance</div>
                                            <div style={{ color: '#fff', fontFamily: 'Poppins', fontWeight: 700, fontSize: '32px' }}>{Math.round(distanceMI)} <span style={{ fontFamily: 'Roboto', color: '#92929D', fontSize: '14px' }}>miles</span></div>
                                            <div style={{ color: '#92929D', fontWeight: 700 }}>or {Math.round(pathData?.distanceKM)} km</div>
                                        </div>
                                        <div style={{flex: '4 0 0 '}}>
                                            <div style={{paddingBottom: '4px'}}>
                                                <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><AllInclusiveIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Hyperloop</div>
                                                    <div>{stringifyHours(calcTravelTime)}</div>
                                                </div>
                                                <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                    <SpeedBar animate={{ width: `${(calcTravelTime/carTravelTime)*100}%` }} />
                                                </div>
                                            </div>
                                            <div style={{paddingBottom: '4px'}}>
                                                <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center', opacity: 0.5}}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><AirplanemodeActiveIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Airplane</div>
                                                    <div>{stringifyHours(planeTravelTime)}</div>
                                                </div>
                                                <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                    <motion.div animate={{ width: `${(planeTravelTime/carTravelTime)*100}%` }} style={{ height: '100%', borderRadius: '8px', backgroundColor: '#ACB2B2'}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'center', opacity: 0.5}}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><DirectionsCarIcon style={{height: '12px', width: 'auto', marginRight: '4px'}}/>Car</div>
                                                    <div>{stringifyHours(carTravelTime)}</div>
                                                </div>
                                                <div style={{marginTop: '4px', height: '8px', width: '100%', backgroundColor: '#4C4C4D', borderRadius: '8px', overflow: 'hidden'}}>
                                                    <motion.div animate={{ width: `100%` }} style={{ height: '100%', borderRadius: '8px', backgroundColor: '#ACB2B2'}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {stationIDs?.map((featureID: string, index: number) => {
                                        // console.log(featureID, index)
                                        // console.log(pathIDs)
                                        // console.log(pathIDs.indexOf(featureID)+1, pathIDs.indexOf(stationIDs[index+1]) - 1)
                                        // console.log(pathIDs.slice(pathIDs.indexOf(featureID)+1, pathIDs.indexOf(stationIDs[index+1])))
                                        const travelDistance = pathIDs.slice(pathIDs.indexOf(featureID)+1, pathIDs.indexOf(stationIDs[index+1])).reduce((acc: number, pid: string) => acc + feature.distances[pid], 0)
                                        const stationDistMi = kmToMi(travelDistance)
                                        const stationTravelTime = stationDistMi / 386 // 386 mph avg speed for Hyperloop

                                        return <div style={{fontFamily: 'Roboto'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{ backgroundColor: (loopStyles as any)[feature?.featureID.split('_')[0]]?.colors.primary, borderRadius: '50%', width: '12px', height: '12px', margin: '16px 0' }}/>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{margin: '0 4px 0 14px', color: loopStyles.colors.secondary, fontFamily: 'Roboto', fontWeight: 600}}>{feature.stationNames[featureID]}</div>
                                                    {index > 0 && index < stationIDs.length - 1 && <div style={{margin: '0 2px', color: loopStyles.colors.secondary, fontSize: '14px', opacity: 0.5, fontFamily: 'Roboto', fontWeight: 600}}>(no stops)</div>}
                                                </div>
                                            </div>
                                            {index !== stationIDs.length - 1 && <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{minWidth: '2px', height: '40px', margin: '0 5px', backgroundColor: 'rgba(255,255,255,0.5)'}} />
                                                <div style={{marginLeft: '16px', fontSize: '14px', letterSpacing: '1px', fontFamily: 'Poppins', color: '#B5B5BE', fontWeight: 600}}>
                                                    {stringifyHours(stationTravelTime)}
                                                </div>
                                            </div>}
                                        </div>
                                    }) }
                                </div> : <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', height: '140px'}}>Info for this trip is currently unavailable.</div>}
                            </motion.div> : <motion.div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140px', color: 'grey', padding: '0 10px', textAlign: 'center'}}>
                                Select a departure/arrival station to view trip info.
                            </motion.div>)}
                        {/* </AnimatePresence> */}
                    </PathList>
                </PathsContainer>
        }

        return content
    }

    // 85 for top padding, 15 for bottom
    return <Container>
        <Tabs
            value={tabIndex}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={{ style: { backgroundColor: loopStyles.colors.tertiary } }}
        >
            {!isPathsEmpty && <Tab 
                onClick={() => { setTab(TabKey.Paths) }} 
                selected={tab === TabKey.Paths} 
                className={classes.tab} 
                label="Trips" 
            />}
            {!isDetailsEmpty && <Tab 
                onClick={() => { setTab(TabKey.Info) }} 
                selected={tab === TabKey.Info}
                className={classes.tab} 
                label="Info" 
            />}
            {!isOptionsEmpty && <Tab 
                onClick={() => { setTab(TabKey.Options) }} 
                selected={tab === TabKey.Options}
                className={classes.tab} 
                label="Forms" 
            />}
            {(!isDetailsEmpty || feature?.isSegmentRoute) && <div style={{display: 'flex', flex: 1, justifyContent: 'end',  alignItems: 'center'}}>
                <div style={{padding: '0 10px',}}>
                    <Toggle toggled={isImperialUnits} onClick={() => {setIsImperialUnits(!isImperialUnits)}}>    
                        <motion.div key={isImperialUnits ? 'US' : 'SI'} initial={{opacity: 0}} exit={{opacity: 0}} animate={{opacity: 1}} >{isImperialUnits ? 'US' : 'SI'}</motion.div>
                        {/* <ToggleItem isSelected={isImperialUnits} onClick={() => {setIsImperialUnits(true)}}>US</ToggleItem>
                        <ToggleItem isSelected={!isImperialUnits} onClick={() => {setIsImperialUnits(false)}}>SI</ToggleItem> */}
                    </Toggle>
                </div>
            </div>}
        </Tabs>
        {getTabContent(tabIndex)}
    </Container>
}

const moveGradient = keyframes`
 0% {
    background-position: -100% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
`

const SpeedBar = styled(motion.div)`
    height: 100%;
    border-radius: 8px;
    background-size: 200%;
    background: linear-gradient(90.17deg, #00FDFD 49.85%, #0094FF 81.19%);
    -webkit-animation: ${moveGradient} 3s linear infinite;
    animation: ${moveGradient} 3s linear infinite;
`

const PathList = styled.div`
    max-height: 100%;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const StationSelect = styled.select`
    margin: 10px 0;
    color: ${loopStyles.colors.secondary};
    background-color: rgba(0,0,0,0.1);
    border: 0;
    border-bottom: 1px solid rgba(0,247,254,0.3);
    outline: 0;
    width: 160px;
    font-family: Roboto;
    padding: 10px;
    &:hover {
        border-bottom: 1px solid rgba(0,247,254,1);
    }
    border-radius: 2px;
    ${loopStyles.transition.animationStyles}
`

const Container = styled.div`
    height: min-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ${loopStyles.mediaQueries.desktop} {
        max-height: calc(100vh - 218px);
    }
    ${loopStyles.mediaQueries.mobile} {
        height: 100%;
        // max-height: 100%;
        max-height: calc(100vh - 188px);
    }
`

const EmptyDetailsLabel = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
    color: #B7B7B7;
`

const Toggle = styled.div<any>`
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid ${loopStyles.colors.tertiary};
    color: ${props => props.toggled ? loopStyles.colors.primary : loopStyles.colors.tertiary};
    background-color: ${props => props.toggled ? loopStyles.colors.tertiary : 'transparent'};
    margin: 8px 0;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
    ${loopStyles.transition.animationStyles}
`

const DetailsContainer = styled.div`
    width: 100%;
    padding: 10px 0;
    height: min-content;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const PathsContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 10px 0 0;
    color: white;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const OptionsContainer = styled.div`
    width: 100%;
    // min-height: 240px;
    flex: 1;
    // height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
    color: white;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

type OptionBubbleProps = {
    selected: boolean
}

const OptionBubble = styled.div<OptionBubbleProps>`
    width: 20px;
    height: 20px;
    border: 1px solid ${loopStyles.colors.tertiary};
    border-radius: 50%;
    background-color: ${props => props.selected ? loopStyles.colors.tertiary : loopStyles.colors.primary};
    color: ${props => props.selected ? loopStyles.colors.primary : loopStyles.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    ${loopStyles.transition.animationStyles}
    &:hover {
       color: ${loopStyles.colors.primary}; 
       background-color: ${loopStyles.colors.tertiary};
    }
`

const ZoomButton = styled.div`
    display: flex;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    align-items: center;
    padding: 6px 8px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    white-space: nowrap;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        borderRadius: '10px 10px 0 0',
        borderLeft: `1px solid #434343`,
        borderRight: `1px solid #434343`,
        borderTop: `1px solid #434343`,
        color: loopStyles.colors.secondary,
        margin: '0 2px',
        transition: 'all 0.2s ease-in',
        '&.Mui-selected, &:hover': {
            borderLeft: `1px solid ${loopStyles.colors.secondary}`,
            borderRight: `1px solid ${loopStyles.colors.secondary}`,
            borderTop: `1px solid ${loopStyles.colors.secondary}`,
        },
    },
    tabs: {
        marginTop: '8px',
        borderBottom: '1px solid #434343',
        color: 'white',
        '&:selected': {
            color: '#1260cc',
        },
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(RouteLayout);