import loopStyles from "common/styles"
import { useEffect, useRef, useState } from "react";
import styled from "styled-components"
import CloseIcon from '@mui/icons-material/Close';
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { selectFeatureViaID, updateHighlightFeatureIDs, updateSelectedSectionIndex, zoomToSelection } from "store/reducers/map";
import { ZoomIn as ZoomInIcon } from '@material-ui/icons';
import RouteCardLayout from "./RouteCardLayout";
import { FeatureType } from "common/constants";
import OfficeCardLayout from "./OfficeCardLayout";
import TeamCardLayout from "./TeamCardLayout";
import CompanyCardLayout from "./CompanyCardLayout";

type MapCardProps = {
    isOpen: boolean,
}

const MapCard = (props: MapCardProps) => {
    const dispatch = useDispatch()

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const menuData = useSelector((state: any) => state.menu.data)
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)

    const [headerHeight, setHeaderHeight] = useState(0)
    const [isExpanded, setIsExpanded] = useState(false);

    const headerRef = useRef(null)

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    const handleResetZoom = (ev: any) => {
        dispatch(updateSelectedSectionIndex(undefined))
        // dispatch(updateHighlightFeatureIDs({
        //     highlightFeatureIDs: undefined
        // }))
        dispatch(zoomToSelection())
        isMobile && isExpanded && toggleExpanded()
        ev.stopPropagation()
    }

    const handleDeselect = (ev: any) => {
        // dispatch(updateHighlightFeatureIDs({
        //     highlightFeatureIDs: undefined
        // }))
        dispatch(updateSelectedSectionIndex(undefined))
        dispatch(selectFeatureViaID())
        ev.stopPropagation()
    }

    // set resize observer that updates headerHeight
    useEffect(() => {
        if (!headerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
          // Do what you want to do when the size of the element changes
          setHeaderHeight((headerRef.current as any)?.clientHeight)
        });
        resizeObserver.observe(headerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [headerRef.current]) // eslint-disable-line

    useEffect(() => {
        if (isOpen === false) {
            setIsExpanded(false)
        }
    }, [props.isOpen]) // eslint-disable-line

    const getHeaderLabel = () => {
        switch (selectedFeatureData?.featureType) {
            case 'office':
                return `${menuData[selectedFeatureData?.companyID]?.name} Office`
            case FeatureType.Company:
                return `${selectedFeatureData?.techType} Company`
            case FeatureType.Team:
                return `Academic Team`
            default:
                return `${menuData && menuData[selectedFeatureData?.companyID]?.name} - ${selectedFeatureData?.companyID === 'BOR' ? 'Loop' : 'Hyperloop'} Route`
        }
    }

    const getCardLayout = () => {
        switch (selectedFeatureData?.featureType) {
            case 'office':
                return <OfficeCardLayout feature={selectedFeatureData}/>
            case FeatureType.Team:
                return <TeamCardLayout feature={selectedFeatureData}/>
            case FeatureType.Company:
                return <CompanyCardLayout feature={selectedFeatureData}/>
            default:
                return <RouteCardLayout feature={selectedFeatureData} toggleExpanded={toggleExpanded}/>
        }
    }

    const { isOpen } = props;

    return <AnimatePresence exitBeforeEnter>
        <Container
            height={headerHeight}
            isExpanded={isExpanded}
            isOpen={isOpen}
        >
            <Header ref={headerRef} onClick={toggleExpanded}>
                <div style={{width: '100%'}}>
                    <SubtitleContainer style={{justifyContent: 'space-between'}}>
                        <AnimatePresence exitBeforeEnter>
                            <motion.div 
                                key={selectedFeatureData?.featureID}
                                {...loopStyles.motion.fadeInOut}
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <IconContainer>
                                    <img 
                                        src={`${process.env.PUBLIC_URL}/icons/companyIcons/${selectedFeatureData?.featureID.split('_')[0]}.png`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src=`${process.env.PUBLIC_URL}/icons/companyIcons/BOR.png`;
                                        }}
                                        alt='' 
                                    />
                                </IconContainer>
                                <div style={{fontFamily: 'Poppins'}}>{
                                    // We should check for companyType here, but can't currently do that without passing featureData down
                                    getHeaderLabel()
                                }</div>
                            </motion.div>
                        </AnimatePresence>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {selectedFeatureData?.featureType !== FeatureType.Company && <IconWrapper onClick={handleResetZoom}>
                                <ZoomInIcon/>
                            </IconWrapper>}
                            <IconWrapper onClick={handleDeselect}>
                                <CloseIcon/>
                            </IconWrapper>
                        </div>
                    </SubtitleContainer>
                    <div style={{marginBottom: '10px'}}>
                        <AnimatePresence exitBeforeEnter>
                            <FeatureName 
                                key={selectedFeatureData?.featureID} 
                                {...loopStyles.motion.fadeInOut}
                                style={{textDecoration: 'underline', textDecorationColor: (loopStyles as any)[selectedFeatureData?.featureID.split('_')[0]]?.colors.primary}}
                            >
                                {selectedFeatureData?.name}
                            </FeatureName>
                        </AnimatePresence>
                    </div>
                </div>
            </Header>
            <Layout height={headerHeight}>
                {getCardLayout()}
            </Layout>
        </Container>
    </AnimatePresence>
}

type LayoutProps = {
    height: number
}

const Layout = styled.div<LayoutProps>`
    ${loopStyles.mediaQueries.desktop} {
        max-height: calc(100vh - 218px);
    }
    ${loopStyles.mediaQueries.mobile} {
        // height: calc(100dvh - ${props => 80 + props.height}px);
        height: calc(100dvh - ${props => 100 + props.height}px);
        max-height: calc(100dvh - ${props => 100 + props.height}px);
        // max-height: 100dvh;
    }
`

const FeatureName = styled(motion.div)`
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    width: 100%;
    max-width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
`

const IconWrapper = styled.div`
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    height: 32px;
    width: 32px;
`

const SubtitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;
    height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #B7B7B7;
    opacity: 0.99;
    ${loopStyles.mediaQueries.desktop} {
        font-size: 14px;
    }
    ${loopStyles.mediaQueries.mobile} {
        font-size: 12px;
    }
`

const Header = styled.div`
    height: min-content;
    max-height: 106px;
    padding: 0px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

type ContainerProps = {
    isExpanded: boolean,
    isOpen: boolean,
    height: number,
}

const Container = styled.div<ContainerProps>`
    position: absolute;
    -moz-transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    -o-transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    border-radius: 16px;
    opacity: ${props => props.isOpen ? 1 : 0};
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #454545;
    background-color: ${loopStyles.colors.primary};
    z-index: 2;
    overflow: hidden;
    ${loopStyles.mediaQueries.desktop} {
        width: 400px;
        top: 70px;
        left: ${props => props.isOpen ? '310px' : '-110px'};
        min-height: min-content;
        // height: calc(100vh - 100px);
        max-height: calc(100vh - 100px);
    }
    ${loopStyles.mediaQueries.mobile} {
        top: ${props => props.isOpen ? (props.isExpanded ? '80px' : `calc(100dvh - ${props.height}px)`) : '100dvh'};
        left: 2vw;
        width: 96vw;
        min-height: -webkit-fill-available;
        min-height: calc(100dvh - ${props => props.height}px);
        height: min-content;
        max-height: calc(100dvh - 100px);
    }
`

export default MapCard