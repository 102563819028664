import loopStyles from "common/styles";
import mapboxgl from 'mapbox-gl'
import { useEffect, useState } from "react"
import styled from "styled-components"
import MenuIcon from '@mui/icons-material/Menu';
import LocationIcon from '@mui/icons-material/MyLocation';
import GlobeIcon from '@mui/icons-material/Language';
import Sidebar from "components/common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenuData } from "store/reducers/menu";
import Map from './Map'
import Modal from "components/common/Modal";
import CloseIcon from '@mui/icons-material/Close';
import AboutLayout from "components/Card/Layouts/AboutLayout";
import FAQLayout from "components/Card/Layouts/FAQLayout";
import MapCard from "./MapCard/MapCard";
import { logout } from "store/reducers/user";
import RouteSidebar from "./Sidebar/RouteSidebar";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import ContactLayout from "components/Card/Layouts/ContactLayout";
import FilterMenu from "./FilterMenu/FilterMenu";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

type MapViewProps = {
    mapboxFeatures: any,
    analytics: any,
    firestoreDB: any,
}

const MapView = (props: MapViewProps) => {
    const dispatch = useDispatch()

    const isMobile = useSelector((state: any) => state.global.isMobile)
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)
    const user = useSelector((state: any) => state.user.user)
    const isUserLoading = useSelector((state: any) => state.user.isLoading)
    const filters = useSelector((state:any) => state.filters)

    const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map | null>(null)
    const [isAboutUsOpen, setIsAboutUsOpen] = useState(false)
    const [isFAQModalOpen, setIsFAQModalOpen] = useState(false)
    const [isContactUsOpen, setIsContactUsOpen] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isAccountOpen, setIsAccountOpen] = useState(false)
    const [isNavigationSidebarOpen, setIsNavigationSidebarOpen] = useState(false)
    
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    const getLocationAndGo = () => {
        if (navigator.geolocation) {
          // TODO: Add error callback so user knows the button isn't just broken
          navigator.geolocation.getCurrentPosition((position: any) => {
            mapboxMap?.setZoom(10.0);
            mapboxMap?.flyTo({ center: [position.coords.longitude, position.coords.latitude] })
          }, () => { console.error('Error getting user position.') }, { timeout:10000 });
        }
    }

    const resetView = () => {
        mapboxMap?.fitBounds([[160.24709996312168, -40.66482502632688], [-130, 67.66452943347628]])
    }
    
    // fetch menu data on page load
    useEffect(() => {
        dispatch(fetchMenuData())
        // console.log(firebase.analytics())
        firebase.analytics().logEvent(`Map loaded!!!!`);
    }, [dispatch])

    useEffect(() => {
        if (isMobile) {
            setIsSidebarOpen(false)
        }
    }, [selectedFeatureData]) // eslint-disable-line

    console.log(user.email)

    return <Container>
        {isMobile && <MobileMapButtons>
            <MobileButton onClick={toggleSidebar}>
                <StyledMenuIcon />
            </MobileButton>
            <MobileButton onClick={getLocationAndGo}>
                <StyledLocationIcon />
            </MobileButton>
            <MobileButton onClick={resetView}>
                <StyledGlobeIcon />
            </MobileButton>
        </MobileMapButtons>}
        <MobileHypermapButton onClick={() => { setIsAccountOpen(!isAccountOpen) }} />
        {/* {!isMobile && <Header>
            <div style={{ width: '298px', color: loopStyles.colors.tertiary, fontFamily: 'OneDay', fontSize: '42px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                HyperMap
            </div>
            <div style={{borderBottom: '2px solid rgb(51, 50, 51)', flex: 1, display: 'flex', color: loopStyles.colors.secondary, alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{display: 'flex'}}>
                    <div style={{ cursor: 'pointer', padding: '10px 12px' }} onClick={() => { setIsModalOpen(!isModalOpen); }}>
                        About
                    </div>
                    <div style={{ cursor: 'pointer', padding: '10px 12px' }} onClick={() => { setIsFAQModalOpen(!isFAQModalOpen); }}>
                        FAQ
                    </div>
                </div>
                <div onClick={() => { dispatch(logout()) }} style={{ cursor: 'pointer', padding: '10px 12px' }}>
                    Log Out
                </div>
            </div>
        </Header>} */}
        <Main>
            <RouteSidebar 
                toggleFAQ={() => { setIsFAQModalOpen(!isFAQModalOpen) }}
                toggleAboutUs={() => { setIsAboutUsOpen(!isAboutUsOpen) }}
                toggleContact={() => { setIsContactUsOpen(!isContactUsOpen) }}
                resetView={resetView}
                toggleSidebar={toggleSidebar} isOpen={isSidebarOpen}
            />
            <Map mapboxMap={mapboxMap} updateMapboxMap={setMapboxMap}/>
            {/* {<Sidebar anchorRight fullscreen onClose={() => { setIsNavigationSidebarOpen(!isNavigationSidebarOpen) }} isOpen={isNavigationSidebarOpen}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div style={{padding: '20px 0'}}>
                        <div style={{ width: '100%', color: loopStyles.colors.tertiary, fontFamily: 'OneDay', fontSize: '42px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0 20px' }}>
                            HyperMap
                        </div>
                        <NavigationSidebarButton onClick={() => { setIsNavigationSidebarOpen(!isNavigationSidebarOpen); setIsAboutUsOpen(!isAboutUsOpen); }}>
                            <span>About Us</span>
                        </NavigationSidebarButton>
                        <NavigationSidebarButton onClick={() => { setIsNavigationSidebarOpen(!isNavigationSidebarOpen); setIsFAQModalOpen(!isFAQModalOpen); }}>
                            <span>FAQ</span>
                        </NavigationSidebarButton>
                    </div>
                    <LoginButton onClick={() => { dispatch(logout()) }}>Log Out</LoginButton>
                </div>
            </Sidebar>} */}
            {<Modal isOpen={isAboutUsOpen} onClose={() => { setIsAboutUsOpen(!isAboutUsOpen) }}>
                <div style={{display: 'flex', color: 'white', height: '60px', alignItems: 'center', borderBottom: `1px solid ${loopStyles.colors.tertiary}`}}>
                    <div style={{flex: 1, fontFamily: 'Poppins', fontSize: '20px', padding: '20px'}}>
                        About Us
                    </div>
                    <CloseIcon onClick={() => { setIsAboutUsOpen(!isAboutUsOpen) }} style={{color: 'white', padding: '20px'}}/>
                </div>
                <AboutLayout/>
            </Modal>}
            {<Modal isOpen={isFAQModalOpen} onClose={() => { setIsFAQModalOpen(!isFAQModalOpen) }}>
                <div style={{display: 'flex', color: 'white', height: '60px', alignItems: 'center', borderBottom: `1px solid ${loopStyles.colors.tertiary}`}}>
                    <div style={{flex: 1, fontFamily: 'Poppins', fontSize: '20px', padding: '20px'}}>
                        FAQ
                    </div>
                    <CloseIcon onClick={() => { setIsFAQModalOpen(!isFAQModalOpen) }} style={{color: 'white', padding: '20px'}}/>
                </div>
                <FAQLayout/>
            </Modal>}
            {<Modal isOpen={isContactUsOpen} onClose={() => { setIsContactUsOpen(!isContactUsOpen) }}>
                <div style={{display: 'flex', color: 'white', height: '60px', alignItems: 'center', borderBottom: `1px solid ${loopStyles.colors.tertiary}`}}>
                    <div style={{flex: 1, fontFamily: 'Poppins', fontSize: '20px', padding: '20px'}}>
                        Contact Us
                    </div>
                    <CloseIcon onClick={() => { setIsContactUsOpen(!isContactUsOpen) }} style={{color: 'white', padding: '20px'}}/>
                </div>
                <ContactLayout/>
            </Modal>}
            {<Modal isOpen={isAccountOpen} onClose={() => { setIsAccountOpen(!isAccountOpen) }}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '300px', padding: '10px 20px' }}>
                    <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{ color: loopStyles.colors.tertiary, fontFamily: 'OneDay', fontSize: '42px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0 20px' }}>
                            HyperMap
                        </div>
                        <div style={{ color: loopStyles.colors.tertiary, fontFamily: 'Roboto', marginBottom: '20px' }}><span style={{color: '#555'}}>logged in as</span> {user.email}</div>
                        {process.env.REACT_APP_BETA_USERS?.includes(user?.email) && <div style={{ color: '#aaa', fontFamily: 'Roboto' }}>beta access</div>}
                    </div>
                    <div style={{width: '100%', display: 'flex'}}>
                        <LoginButton onClick={() => { dispatch(logout()) }}>Log Out</LoginButton>
                    </div>
                </div>
            </Modal>}
            <MapCard isOpen={!filters.isOpen && selectedFeatureData}/>
            <FilterMenu isOpen={filters.isOpen}/>
        </Main>
    </Container>
}

const Main = styled.div`
    display: flex;
    width: 100%;
    ${loopStyles.mediaQueries.desktop} {
        height: 100%;
    }
    ${loopStyles.mediaQueries.mobile} {
        height: 100%;
    }
`

const Header = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    background-color: rgba(35, 34, 36, 1);
`

const NavigationSidebarButton = styled.div`
    color: ${loopStyles.colors.secondary};
    height: 40px;
    padding: 0 20px;
    * { border-bottom: 1px solid ${loopStyles.colors.tertiary}; }
    font-size: 32px;
    font-family: Poppins;
    :not(:last-child) {
        margin-bottom: 20px;
    }
`

const LoginButton = styled.button`
    flex: 1;
    background-color: ${loopStyles.colors.primary};
    color: ${loopStyles.colors.secondary};
    font-size: 20px;
    font-family: Poppins;
    height: 40px;
    margin: 10px 20px;
    border: 1px solid ${loopStyles.colors.tertiary};
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        color: black;
        background-color: ${loopStyles.colors.tertiary};
    }
    ${loopStyles.transition.animationStyles}
`

const MobileMapButtons = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
`

const MobileHypermapButton = styled.div`
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    // background-color: ${loopStyles.colors.tertiary};
    cursor: pointer;
    z-index: 99;
    background-size: cover;
    background-image: url(${process.env.PUBLIC_URL}/hyperloopFav.png);
    &:hover {
        transform: scale(1.1);
    }
    ${loopStyles.transition.animationStyles}
`


const StyledMenuIcon = styled(MenuIcon)`
    color: ${loopStyles.colors.secondary};
    opacity: 0.8;
`

const StyledLocationIcon = styled(LocationIcon)`
    color: ${loopStyles.colors.secondary};
    opacity: 0.8;
`

const StyledGlobeIcon = styled(GlobeIcon)`
    color: ${loopStyles.colors.secondary};
    opacity: 0.8;
`

const MobileButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: ${loopStyles.colors.primary};
    border-radius: 50%;
    border: 1px solid ${loopStyles.colors.tertiary};
    z-index: 2;
    :not(:last-child) {
        margin-bottom: 12px;
    }
`

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    max-height: 100dvh;
    background-color: rgba(35, 34, 36, 0.9);
    position: relative;
`

export default MapView