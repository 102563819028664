import loopStyles from "common/styles"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import styled from "styled-components"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useDispatch, useSelector } from "react-redux";
import { Feature } from "common/feature";
import { selectFeatureViaID } from "store/reducers/map";

type SidebarGroupProps = {
    children?: any,
    header?: any,
    company?: any,
}

const SidebarGroup = (props: SidebarGroupProps) => {
    const dispatch = useDispatch()

    const filters = useSelector((state: any) => state.filters)
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)

    const [isOpen,  setIsOpen] = useState(false)
    const [isShowingRoutes, setIsShowingRoutes] = useState(true)

    const toggleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    const filterVisibleFeatures = (feature: any) => {
        // console.log(filters.status, feature, filters.status.has(feature.status))
        if (filters.status.length > 0 && !filters.status.includes(feature.status)) {
            return false
        }

        return true
    }

    const mapRouteOrPointToListItem = (feature: Feature, index: number): React.ReactElement => {
        const selected = selectedFeatureData?.featureID === feature.featureID;
        const companyStyles = loopStyles[company.featureID];

        const selectFeature = () => {
            dispatch(selectFeatureViaID({ featureID: feature.featureID }))
        }

        return <ListItem key={index} color={companyStyles?.colors.primary} onClick={selectFeature}>
            {/* <Bg color={companyStyles?.colors.primary} /> */}
            <ListItemIconContainer
                color={companyStyles?.colors.primary || '#FAE900'}
                onClick={selectFeature}
            >
                {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </ListItemIconContainer>
            <div style={{flex: 1, whiteSpace: 'normal', marginLeft: '10px', fontFamily: 'Poppins', fontSize: '14px', color: '#B5B5BE', zIndex: 999}}>{feature.name}</div>
        </ListItem>
    }

    const { company } = props;

    const featureItems = (isShowingRoutes ? company.routes : company.points)?.filter(filterVisibleFeatures).sort((a: any,b: any) => Number(a.menuSortOrder) - Number(b.menuSortOrder)).map(mapRouteOrPointToListItem)

    return <Container>
        <div onClick={toggleIsOpen}>
            <SidebarGroupHeader isOpen={isOpen} color={loopStyles[company?.featureID]?.colors.primary}>
                <div style={{display: 'flex', alignItems: 'center', width: '30px', height: 'auto', justifyContent: 'center', marginRight: '10px', color: isOpen ? '#FFFFFF' : ''}}>
                    <img src={`${process.env.PUBLIC_URL}/icons/companyIcons/${['CASIC', 'ERT', 'TUTR'].includes(company.featureID) ? 'BOR' : company.featureID}.png`} alt={``} style={{ maxWidth: '24px' }}/>
                </div>
                <div style={{flex: 1, textAlign: 'start', marginLeft: '0px', whiteSpace: 'nowrap', fontSize: '14px', userSelect: 'none'}}>{company?.name}</div>
                <CompanyFilterButton 
                    key={company?.featureID}
                    selected={selectedFeatureData?.featureID === company?.featureID}
                    onClick={(ev: any) => { 
                        ev.stopPropagation();
                        dispatch(selectFeatureViaID({ featureID: company.featureID }))
                    }}
                    color={(loopStyles[company?.featureID]?.colors.primary || '#FAE900')}
                />
                {isOpen ? <ExpandMore /> : <ExpandLess />}
            </SidebarGroupHeader>
        </div>
        <AnimatePresence>
            {isOpen && <ExpandableChildren 
                initial={{ height: 0, opacity: 0 }}
                exit={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                transition={{ duration: 0.2, type: "tween" }}
            >
                <div style={{padding: '20px 0 10px'}}>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '8px'}}>
                        <FilterTab selected={isShowingRoutes} onClick={() => { setIsShowingRoutes(true) }}>Routes</FilterTab>
                        <FilterTab selected={!isShowingRoutes} onClick={() => { setIsShowingRoutes(false) }}>Offices</FilterTab>
                    </div>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div 
                            key={isShowingRoutes.toString()}
                            initial={{opacity: 0,}}
                            exit={{opacity: 0,}}
                            animate={{opacity: 1,}}
                            transition={{duration: 0.2}}
                        >
                            {featureItems?.length ? featureItems : <EmptyMessage>{`There are no ${isShowingRoutes ? 'routes' : 'offices' } to display.`}</EmptyMessage>}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </ExpandableChildren>}
        </AnimatePresence>
    </Container>
}

type ListItemIconContainerProps = {
    color?: string,
}

const ListItemIconContainer = styled.div<ListItemIconContainerProps>`
    color: ${props => props.color ? props.color : loopStyles.colors.tertiary};
    width: 36px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ListItem = styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    ${loopStyles.transition.animationStyles}
    cursor: pointer;
    border-left: 2px solid transparent;
    text-decoration-color: ${(props: any) => props.color};
    &:hover {
        background-color: rgba(255,255,255,0.05);
        text-decoration: underline;
        border-left: 2px solid ${loopStyles.colors.tertiary};
        text-decoration-color: ${(props: any) => props.color};
    }
`

const EmptyMessage = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B5B5BE;
    opacity: 0.7;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.1px;
`

type SelectedProps = {
    selected: boolean,
}

const FilterTab = styled.div<SelectedProps>`
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #B5B5BE;
    cursor: pointer;
    margin: 0 8px 8px;
    transition: all 0.2s ease-in;
    border: 1px solid ${loopStyles.colors.tertiary};
    opacity: ${props => props.selected ? '1 !important' : 0.3};
    &:hover {
        opacity: 0.6;
    }
`

type SidebarGroupHeaderProps = {
    isOpen: boolean,
    color: string,
}

const SidebarGroupHeader = styled.div<SidebarGroupHeaderProps>`
    color: #B5B5BE;
    max-width: 100%;
    height: 40px;
    font-family: Poppins;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.isOpen ? props.color: 'transparent'};
    ${loopStyles.transition.animationStyles}
    &:hover {
        background-color: rgba(0,0,0,0.1);
        border-bottom: 1px solid ${props => props.color};
    }
`

const ExpandableChildren = styled(motion.div)`
    color: ${loopStyles.colors.secondary};
    background-color: rgba(0,0,0,0.3);
    overflow: hidden;
`

const Container = styled.div`
    background-color: ${loopStyles.colors.primary};    
`

type CompanyFilterButtonProps = {
    color: string,
    selected: boolean,
}

const CompanyFilterButton = styled.button<CompanyFilterButtonProps>`
    width: 20px;
    aspect-ratio: 1;
    border: 1px solid ${props => props.color};
    cursor: pointer;
    margin-right: 20px;
    background-color: ${props => props.selected ? props.color : 'transparent'};
    border-radius: 50%;
    ${loopStyles.transition.animationStyles}
    ${loopStyles.mediaQueries.desktop} {
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        min-height: 20px;
        max-height: 20px;
        &:hover {
            border: 3px solid ${props => props.color};
        }
    }
`

export default SidebarGroup