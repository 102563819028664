import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core"
import loopStyles from "common/styles";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createAccount, login } from "store/reducers/user";
import { Close as CloseIcon } from '@material-ui/icons';
import { selectFeatureViaID } from "store/reducers/map";

type AuthLayoutProps = {
    classes: any
}

enum AuthSteps {
    Authenticate,
    Login,
    Signup
}

const AuthLayout = (props: AuthLayoutProps) => {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.user.user)

    const [step, setStep] = useState(AuthSteps.Authenticate)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    // 8-16 characters, 1 uppercase, 1 lowercase, 1 number, can contain special characters ($ % # * & - .)
    const isPasswordValid = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-#$.%&*])(?=.*[a-zA-Z]).{8,16}$/.test(password)
    const isPasswordsMatch = isPasswordValid && password === password2
    const isSignUpInputValid = isEmailValid && isPasswordValid && isPasswordsMatch

    const handleLogin = () => {
        dispatch(login({ email, password }))
    }

    const handleCreateAccount = () => {
        if (true /*emailIsValid && passwordsMatch*/) {
            dispatch(createAccount({ email, password }))
        }
    }

    const getStepContent = () => {
        switch (step) {
            case AuthSteps.Signup:
                return <div style={{display: 'flex', flexDirection: 'column', margin: '20px', fontFamily: 'Poppins', color: loopStyles.colors.secondary}}>
                    <Title>Sign Up</Title>
                    <InputLabel>Email Address</InputLabel>
                    <TextInput
                        isValid={isEmailValid}
                        autoComplete="new-password"
                        value={email}
                        onChange={(ev) => { setEmail(ev.target.value) }}
                    />
                    <PasswordDetails>
                        <div>Passwords must contain:</div>
                        <div style={{marginLeft: '8px'}}>
                            <div>&bull; 8-16 characters</div>
                            <div>&bull; 1 uppercase letter</div>
                            <div>&bull; 1 lowercase letter</div>
                            <div>&bull; 1 number</div>
                        </div>
                    </PasswordDetails>
                    <InputLabel>Password</InputLabel>
                    <TextInput
                        isValid={isPasswordValid}
                        autoComplete="new-password"
                        type='password'
                        value={password} onChange={(ev) => { setPassword(ev.target.value) }}
                    />
                    <PasswordDetails>Passwords must match</PasswordDetails>
                    <InputLabel>Re-enter Password</InputLabel>
                    <TextInput
                        isValid={isPasswordsMatch}
                        autoComplete="new-password"
                        type='password'
                        value={password2} onChange={(ev) => { setPassword2(ev.target.value) }}
                    />
                    <Button disabled={!isSignUpInputValid} onClick={handleCreateAccount} className={props.classes.button}>Create Account</Button>
                </div>
            case AuthSteps.Login:
                return <div style={{display: 'flex', flexDirection: 'column', margin: '20px', fontFamily: 'Poppins', color: loopStyles.colors.secondary}}>
                    <Title>Log In</Title>
                    <InputLabel>Email Address</InputLabel>
                    <TextInput value={email} onChange={(ev) => { setEmail(ev.target.value) }}/>
                    <InputLabel>Password</InputLabel>
                    <TextInput type='password' value={password} onChange={(ev) => { setPassword(ev.target.value) }}/>
                    <Button onClick={handleLogin} className={props.classes.button}>Login</Button>
                </div>
            case AuthSteps.Authenticate:
            default:
                return <div style={{display: 'flex', flexDirection: 'column', margin: '20px', fontFamily: 'Poppins', color: loopStyles.colors.secondary}}>
                    <Title>Log In or Sign Up</Title>
                    <div style={{color: '#999999', fontSize: '14px', margin: '10px 0'}}>Log in or sign up with a free account to view more details, like travel time, route status, and social media links</div>
                    <Button onClick={() => { setStep(AuthSteps.Signup) }} className={props.classes.button}>Sign Up</Button>
                    <Button onClick={() => { setStep(AuthSteps.Login) }} className={props.classes.button}>Login</Button>
                </div>
        }
    }

    useEffect(() => {
        setEmail('')
        setPassword('')
        setPassword2('')
    }, [ step ])

    return <div style={{ maxHeight: `${window.innerHeight - (85 + 20)}px`, display: 'flex', flexDirection: 'column'}}>
        <div style={{ width: '400px' }}>
            <IconWrapper onClick={() => { dispatch(selectFeatureViaID()) }}>
                    <CloseIcon/>
            </IconWrapper>
            {user?.emailVerified === false ? <div style={{position: 'relative', display: 'flex', flexDirection: 'column', margin: '20px', fontFamily: 'Poppins', color: loopStyles.colors.secondary}}>
                <Title>Verify Email</Title>
                <div style={{color: '#999999', fontSize: '14px', margin: '10px 0'}}>
                    <div>You are logged in, but have not verfied your email address.</div>
                    <br/>
                    <div>Check <span style={{color: loopStyles.colors.tertiary}}>{user?.email}</span> for a verfication email.</div>
                    <br/>
                    <div>If you have verified your email and still see this message, refresh the page or contact <span style={{color: loopStyles.colors.tertiary}}>contact@hypermap.co</span> for assistance.</div>
                </div>
            </div> : getStepContent()}
        </div>
    </div>
}

const PasswordDetails = styled.div`
    font-family: Poppins;
    color: ${loopStyles.colors.secondary};
    opacity: 0.8;
    margin-bottom: 14px;
    font-size: 14px;
`

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid ${loopStyles.colors.tertiary};
    width: min-content;
    white-space: nowrap;
    font-size: 20px;
    padding: 0 4px 4px;
`

const InputLabel = styled.div`
    font-family: Poppins;
    color: ${loopStyles.colors.secondary};
    margin-bottom: 4px;
`

const IconWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    padding: 4px;
    height: min-content;
    z-index: 10;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

type TextInputProps = {
    isValid?: boolean
}

const TextInput = styled.input<TextInputProps>`
    background-color: rgba(0,0,0,0.1) !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${props => props.isValid === false ? loopStyles.colors.secondary : loopStyles.colors.tertiary};
    font-family: Poppins;
    flex: 1;
    margin-bottom: 20px;
    padding: 4px 10px;
    border-radius: 0;
    outline: 0;
    color: ${loopStyles.colors.secondary};
    ${loopStyles.transition.animationStyles}
`

const styles = {
    button: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 100,
        letterSpacing: '0.1px',
        color: loopStyles.colors.primary,
        margin: '10px  0',
        backgroundColor: loopStyles.colors.tertiary,
        border: `1px solid transparent`,
        '&:hover': {
            backgroundColor: 'transparent',
            color: loopStyles.colors.tertiary,
            border: `1px solid ${loopStyles.colors.tertiary}`,
        },
        '&:disabled': {
            border: `1px solid ${loopStyles.colors.secondary}`,
            backgroundColor: 'transparent',
            color: loopStyles.colors.secondary,
            opacity: 0.5,
        }
    },
};

export default withStyles(styles)(AuthLayout);